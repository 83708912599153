import io from 'socket.io-client'
import { socketURL } from './config'

console.log('socketURL', socketURL)
let socket = {}

socket.connect = function() {
  socket.io = io(socketURL)
  return this
}

socket.getConnection = function() {
  console.log(socketURL)
  if (socket) {
    return this.io
  } else {
    socket = io(socketURL)
    return this.io
  }
}

socket.on = function(eventName, callback) {
  console.log("eventName", eventName)
  console.log("callback", callback)
  socket.io.on(eventName, callback)
  return this
}

socket.emit = function(eventName, data) {
  socket.io.emit(eventName, data)
  return this
}

export default socket
