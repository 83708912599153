import React from "react";
import { Button, Checkbox, Form, Input, message, Row, Col } from "antd";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
// import { fingerprintjs2 } from 'fingerprintjs2'
import { hideMessage, showAuthLoader, userResetPassword, clearUserData } from "../../appRedux/actions/Auth";
import IntlMessages from "util/IntlMessages";
import CircularProgress from "components/CircularProgress";

import "./../../assets/css/uikit.css";
import "./../../assets/css/main.css";
import "./../../assets/css/fontawesome.css";

import socket from "./../../util/socket";
import rsaWrapper from "./../../util/rsa-wrapper";
import aesWrapper from "./../../util/aes-wrapper";

import { IconFont } from "util/Utils";
import { getSubdomain } from '../../util/Utils';

const fingerprintjs2 = require("fingerprintjs2");
const cookie = require("js-cookie");
const FormItem = Form.Item;

const convertToObject = (url) => {
  const arr = url.slice(1).split(/&|=/); // remove the "?", "&" and "="
  let params = {};
  for (let i = 0; i < arr.length; i += 2) {
    const key = arr[i],
      value = arr[i + 1];
    params[key] = value; // build the object = { limit: "10", page:"1", status:"APPROVED" }
  }
  return params;
};

class ResetPassword extends React.Component {
  state = {
    userData: null,
    connection: false,
  };
  handleSubmit = (e) => {
    message.destroy();
    this.props.hideMessage();
    const { location, match } = this.props;
    const { params } = match;
    var objectData = {};
    if (params.reset_code) {
      objectData["reset_code"] = params.reset_code;
      objectData["isTrainee"] = true;
    } else {
      objectData = convertToObject(location.search);
      objectData["isTrainee"] = false;
    }
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      console.log({ values });
      if (!err && this.AESKey) {
        this.props.showAuthLoader();
        localStorage.setItem("AESKey", this.AESKey);
        aesWrapper.encryptMessage(this.AESKey, values.password).then((encryptedPassword) => {
          values.password = encryptedPassword;
          objectData["password"] = encryptedPassword;
          console.log({ objectData });
          this.props.userResetPassword(objectData);
        });
      }
    });
  };

  handleOk = (event) => {
    const { dispatch, form } = this.props;

    const { validateFieldsAndScroll } = form;
    validateFieldsAndScroll((errors, values) => {
      if (errors) {
        return;
      }
      // dispatch({ type: 'changePassword/changePassword', payload: values })
    });
    event.preventDefault();
  };

  handleConfirmBlur = (e) => {
    const { value } = e.target;
    this.setState({ confirmDirty: this.state.confirmDirty || !!value });
  };

  compareToFirstPassword = (rule, value, callback) => {
    const { form } = this.props;
    if (value && value !== form.getFieldValue("password")) {
      callback("Password mismatch");
    } else {
      callback();
    }
  };

  validateToNextPassword = (rule, value, callback) => {
    const { form } = this.props;
    if (value && this.state.confirmDirty) {
      form.validateFields(["confirm"], { force: true });
    }

    // var regularExpression  = /^[a-zA-Z0-9!@#$%^&*]$/;
    var regularExpression = /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[0-9]).{4,}$/;
    if (!regularExpression.test(value)) {
      callback("password should contain at least 1 special character, number and character");
    } else {
      callback();
    }
  };
  componentWillMount() {
    window.scrollTo(0, 0);
    message.destroy();
    this.props.hideMessage();
   
    const { location, match } = this.props;
    const { params } = match;
    if (!params.reset_code) {
      var objectData = convertToObject(location.search);
      if (!objectData.reset_code) {
        this.props.history.push("/");
      }
    }

    this.props.hideMessage();
    this.socketConnect();
  }

  componentDidMount() {
    message.destroy();
    this.props.hideMessage();
    var self = this;
    document.addEventListener("resetPassword", function(e) {
      console.log("e.detail.success", e.detail.isSuccess);

      if (e.detail.isSuccess) {
        self.props.history.push("/signin");
      }
    });
  }
  componentDidUpdate() {
    var they = this;
    if (this.props.authUser === null && !they.state.connection) {
      this.socketConnect();
    }
    if (this.props.showMessage) {
      setTimeout(() => {
        this.props.hideMessage();
      }, 100);
    }
    const { location, match } = this.props;
    const { params } = match;
    var objectData = {};
    if (params.reset_code) {
      objectData["reset_code"] = params.reset_code;
    } else {
      objectData = convertToObject(location.search);
    }

    if (!objectData.reset_code && this.props.authUser === null) {
      this.props.history.push("/");
    }
    if (this.props.authUser !== null) {
      this.props.history.push("/");
    }
    if (this.props.userData !== null) {
      this.setState({
        userData: this.props.userData,
      });
      this.props.clearUserData();
    }
  }

  socketConnect() {
    socket.connect();
    var they = this;
    socket.on("CLIENT_PRIVATE_KEY", (privateKey) => {
      socket.emit("SECRET_KEY");
      socket.on("SECRET_KEY", (key) => {
        rsaWrapper
          .privateDecrypt(privateKey, key)
          .then((SECRET_KEY) => {
            they.AESKey = SECRET_KEY;
            they.setState({ connection: true });
            if (window.requestIdleCallback) {
              requestIdleCallback(function() {
                fingerprintjs2.get(function(components) {
                  const fingerprint = fingerprintjs2.x64hash128(
                    components
                      .map(function(pair) {
                        return pair.value;
                      })
                      .join(),
                    31
                  );
                  cookie.set("fngprt", fingerprint);
                });
              });
            } else {
              setTimeout(function() {
                fingerprintjs2.get(function(components) {
                  const fingerprint = fingerprintjs2.x64hash128(
                    components
                      .map(function(pair) {
                        return pair.value;
                      })
                      .join(),
                    31
                  );
                  cookie.set("fngprt", fingerprint);
                });
              }, 500);
            }
          })
          .catch((err) => {
            console.log("error occurred ", err.name);
            console.log("error occurred code ", err.code);
          });
      });
    });
  }

  messageDestroy = (e) => {
    message.destroy();
    this.props.hideMessage();
  };
  render() {
    const { getFieldDecorator } = this.props.form;
    const { showMessage, loader, alertMessage, successMessage } = this.props;
    return (
      <div
      style={{
        backgroundColor: "#f9f9f9",
        // backgroundImage: "linear-gradient(180deg,#aecee1,#acb0b8)"
        backgroundImage: "linear-gradient(180deg,#adadad,#101820)",
        // backgroundImage: "url(" + require("assets/images/backgrounds/login_bg_image.png") + ")",
      }}
    >
       {showMessage && alertMessage != "" ? message.error(alertMessage.toString()) : null}
        {showMessage && successMessage != "" ? message.success(successMessage.toString()) : null}
      
      <div className="uk-flex uk-flex-middle" style={{ marginBottom: "-65px" }}>
        {/* <IconFont 
          type="icon-logo_web"
          style={{
            fontSize: "120px",
            marginTop: "0px",
            marginLeft: "45px",
          }}
        ></IconFont> */}
        <i className={ getSubdomain() !== "geidea" ? ("fa fa-logo-black-icon " + getSubdomain()) : "fa fa-logo-black-icon geidea"} style={{
              // fontSize: "120px",
              marginTop: "20px",
              marginLeft: "45px",
            }}></i>
        </div>
        <div data-uk-height-viewport="offset-top: true; offset-bottom: true" className="uk-flex uk-flex-middle">
          <div className="uk-width-2-3@m uk-width-1-2@s uk-margin-auto  border-radius-6 ">
          <div
              className="uk-child-width-1-2@m uk-background-grey uk-grid-collapse"
              data-uk-grid
              style={{ borderRadius: 10,margin:"0 40px" }}
            >
              <div  > 
                <img
                  className="welcome-image-login"
                  src={require("assets/images/welcome_image_1.png")} 
                /> 
              </div>

              <div id="login_container" style={{ borderRadius: 10 }}>
                <div
                  className="uk-card-default uk-padding uk-card-small custom-login-view"
                 
                >
                  {loader ? (
                    <div
                      style={{
                        position: "absolute",
                        left: "47.7%",
                        top: "-5%",
                        zIndex: "999",
                      }}
                    >
                      <CircularProgress />
                    </div>
                  ) : null}
                  {/* <!-- Login tab tab --> */}
                    <div id="login" className="tabcontent tab-default-open animation: uk-animation-slide-right-medium custom-login-view-2" >
                      <div className="gx-app-login-content" style={{ width: "100%" }}>
                        <h2
                          className="uk-text-bold"
                          style={{ textAlign: "end", marginBottom: "40px", color: "#000", marginRight: "10px" }}
                        >
                        {" "}
                        <IntlMessages id="app.userAuth.resetPassword" />
                      </h2>
                      <p className="uk-text-muted uk-margin-remove-top uk-margin-small-bottom">
                        {" "}
                        <IntlMessages id="app.userAuth.setYourNewPassword" />{" "}
                      </p>

                      <Form className="gx-signin-form gx-form-row0">
                        {/* <div className="uk-form-label">New Password </div> */}
                        <FormItem>
                          {getFieldDecorator("password", {
                            rules: [
                              {
                                required: true,
                                pattern: /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[0-9]).{4,}$/,
                                message: "Please input new password!",
                              },
                              {
                                validator: this.validateToNextPassword,
                              },
                            ],
                          })(
                            <Input
                              className="uk-input uk-form-width-large"
                              type="password"
                              onChange={this.messageDestroy}
                              onPressEnter={this.handleOk}
                              placeholder={`New Password`}
                            />
                          )}
                        </FormItem>
                        {/* <div className="uk-form-label"> Confirm Password </div> */}
                        <FormItem>
                          {getFieldDecorator("confirmPassword", {
                            rules: [
                              {
                                required: true,
                                message: "Please input confirm password!",
                              },
                              {
                                validator: this.compareToFirstPassword,
                              },
                            ],
                          })(
                            <Input
                              onBlur={this.handleConfirmBlur}
                              className="uk-input uk-form-width-large"
                              type="password"
                              onChange={this.messageDestroy}
                              onPressEnter={this.handleOk}
                              placeholder={`Confirm Password`}
                            />
                          )}
                        </FormItem>

                        <FormItem className="btn-login">
                          <Button
                            id="next_btn"
                            // type="primary"
                            className="gx-mb-0 gx-next-btn uk-button-success"
                            htmlType="button"
                            onClick={this.handleSubmit}
                          >
                            <IntlMessages id="app.userAuth.resetPassword" />
                          </Button>
                        </FormItem>

                        <FormItem>
                          <span className="gx-signup-form-forgot gx-link login-link-color">
                            <Link to="/signin">
                              <IntlMessages id="app.userAuth.backToLogin" />
                            </Link>
                          </span>
                        </FormItem>
                      </Form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const WrappedNormalForm = Form.create()(ResetPassword);

const mapStateToProps = ({ auth }) => {
  console.log({ auth });
  const { loader, alertMessage, successMessage, showMessage, authUser, userData, accessToken } = auth;
  return {
    loader,
    alertMessage,
    successMessage,
    showMessage,
    authUser,
    userData,
    accessToken,
  };
};

export default connect(
  mapStateToProps,
  {
    hideMessage,
    showAuthLoader,
    userResetPassword,
    clearUserData,
  }
)(WrappedNormalForm);
