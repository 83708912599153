import React from "react";

import { Link } from "react-router-dom";
import "./index.css";
import { IconFont } from "util/Utils";
import { getSubdomain } from '../../../util/Utils';
function NavbarWeb(info) {
  const { onClickViewModel, visibleIcon } = info;
  return (
    <div className="tm-header uk-visible@m tm-header-transparent uk-margin-top">
      <div data-uk-sticky=" uk-sticky media:768; animation:uk-animation-slide-top; cls-active:uk-navbar-sticky uk-nav-dark uk-navbar-container;   top: 555; cls-inactive:uk-navbar-transparent   uk-dark ; class:uk-sticky uk-navbar-container;">
        <div className="uk-container uk-position-relative">
          <nav
            className="uk-navbar uk-navbar-transparent"
            style={{ height: "55px" }}
          >
            {/* <!-- logo --> */}
            <div clasName="uk-navbar-left">
              {visibleIcon ? (
                <Link to="/" className="uk-logo ">
                  {/* <i className="fa fa-custom-logo-white"></i> icon-Ophish_menu_logo2 */}
                  
              <i className={getSubdomain() !== "geidea" ? ("fa fa-logo-white-icon " + getSubdomain()) : "fa fa-logo-white-icon geidea"}></i>
                  {/* <IconFont
                    type="icon-logo_web_white"
                    style={{ fontSize: "150px", marginTop: "-64px" }}
                  ></IconFont> */}
                </Link>
              ) : (
                <Link to="/" className="uk-logo ">
                <i className={getSubdomain() !== "geidea" ? ("fa fa-logo-black-icon " + getSubdomain()) : "fa fa-logo-black-icon geidea"}></i>
                  {/* <IconFont
                    type="icon-logo_web"
                    style={{ fontSize: "150px", marginTop: "-64px" }}
                  ></IconFont> */}
                </Link>
              )}
            </div>
            {/* <!-- right navbar  --> */}
            <div className="uk-navbar-right">
              <ul className="uk-navbar-nav toolbar-nav">
                <li className="uk-active">
                  <Link to="/">Home</Link>
                </li>
                {/* <li>
                                    <Link to='/blog'>Blog
                                    </Link>   
                                </li>                                  */}
                <li>
                  <a href="#" onClick={onClickViewModel}>
                    Demo
                  </a>
                </li>
                {/* <li>
                                    <Link to='/trainee'>Trainee
                                    </Link>  
                                </li> */}
                <li>
                  <Link to="/signin"> Sign In</Link>
                </li>
              </ul>

              {/* <Link className="el-content uk-button uk-button-success uk-circle" to='/free-trailer'> Free Trailer
                                    </Link>  */}
              <a
                className="el-content uk-button uk-button-success uk-circle"
                href="#freeTraining"
                data-uk-tobottom
                data-uk-scroll
              >
                {" "}
                Free Trial{" "}
              </a>

              {/* <a className="el-content uk-button uk-button-success uk-circle" href="#freeTraining">
                {" "}
                Free Trial{" "}
              </a> */}
            </div>
          </nav>
        </div>
      </div>
    </div>
  );
}

export default NavbarWeb;
