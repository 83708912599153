import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { Modal, Button, Table, Row, Col, Rate, Input } from "antd";
// import {DropOption} from '../../../components'
import styles from "./index.less";

const { TextArea } = Input;
const { confirm } = Modal;
const receiveHtml = content => {};

const modalRatings = info => {
  console.log({ info });
  const {
    modalVisible,
    handleCancel,
    setReviews,
    giveRating,
    onSubmit,
    trainingId
  } = info;
  const onChangeRateing = value => {
    console.log({ value });
  };
  const onChangeText = text => {
    console.log({ text });
  };
  return (
    <Modal
      // title="Training description"
      visible={modalVisible}
      // onOk={onSubmit}
      onCancel={handleCancel}
      footer={null}
      destroyOnClose={false}
      width="50%"
      style={{ top: "5%" }}
    >
      <div style={{ textAlign: "center" }}>
        <img
          src={require("assets/images/star_icon.png")}
          style={{ width: "30%" }}
        />
      </div>
      <div style={{ textAlign: "center" }}>
        <div className="uk-h4   uk-text-center uk-margin-top">
          {/* Thanks for watching our video. Please Rate us. */}
          Thanks for completing the training.
        </div>
      </div>
      <div style={{ textAlign: "center" }}>
        <Rate onChange={giveRating} style={{ fontSize: "28px" }} />
      </div>

      <div
        style={{ margin: "30px 10px", border: "1px solid rgb(232, 232, 232)" }}
      ></div>
      <Row>
        <Col xl={3} lg={3} md={3} sm={24} xs={24}></Col>
        <Col xl={18} lg={18} md={18} sm={24} xs={24}>
          <TextArea
            className={styles.textAreaStyle}
            className="topic11"
            rows={4}
            placeholder="Additional comment"
            onChange={setReviews}
          />
          <div style={{ textAlign: "center", margin: "25px 0px 0px 0px" }}>
            <Button
              onClick={() => {
                onSubmit(trainingId);
              }}
              className="topic4"
              type="default"
              shape="round"
              style={{ border: "#30826C !important", color: "#ffffff !important" }}
            >
              Submit
            </Button>
            <Button
              onClick={handleCancel}
              className="topic10"
              type="default"
              shape="round"
              style={{ border: "#b5b5b5 !important"  }}
            >
              Cancel
            </Button>
          </div>
        </Col>
        <Col xl={3} lg={3} md={3} sm={24} xs={24}></Col>
      </Row>
    </Modal>
  );
};

export default modalRatings;
