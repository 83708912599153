import React, { PureComponent } from "react";
import {
  Form,
  Input,
  Tooltip,
  Icon,
  Select,
  Row,
  Col,
  Button,
  Checkbox,
  Upload,
  message,
  Switch
} from "antd"; 

// import 'antd/dist/antd.css';  
import styles from "./trainingForm.less";

import ViewModalDescription from "./modalDescription";
import ViewSCORMPackageModal from "./SCORMPackageModal";

import ModelTraining from "../../../traineeMain/model";
import ModelInstructor from "../../instructor/model";
import UploadVideo from "./uploadVideo"
import { api, apiUrl } from "util/config";
import store from "store";
const { TextArea } = Input;
const { Option } = Select;
var languageData = [
  { label: "All", value: "all" },
  { label: "English(United States)", value: "en-us" },
  { label: "English(Australia)", value: "en-au" },
  { label: "Bahasa Malaysia", value: "myb" },
  { label: "Arabic", value: "ar" },
]
class TrainingForm extends PureComponent {
  state = {
    visible: false,
    viewModel: false,
    content: "",
    isFree: false,
    training: {},
    topicsList: [],
    instructorList: [],
    languageList:[],
    languageValueList:[],
    change: true,
    viewModelSCORM:false,
    showWeb:true,
    uploadStatus: true
  };
  componentWillMount() {
    const { training } = this.props;
    this.setState({ training: training, showWeb: training.showWeb, isFree: training.isFree,trainingVideoUrl:training.videoUrl });

    this.fetchTrainingCategory();
    this.fetchTrainingInstructor();
  }
  fetchTrainingCategory() {
    var self = this;
    ModelTraining.fetchTrainingCategory().then(response => {
      var subCategory = response.data.subCategory.filter(
        item => item !== null && item !== ""
      );
      // subCategory.splice(0, 0, "All");
      var language = response.data.language||languageData; 
      if(language[0]["value"] == "all"){
        language.splice(0, 1);
      }
      var languageValueList = []
      for(let i = 0 ; i < language.length ; i++){
        languageValueList.push(language[i].value)
      }
      console.log({language})
      self.setState({
        topicsList: subCategory,
        languageList: language,
        languageValueList
      }); 
    });
  }
  fetchTrainingInstructor() {
    var self = this;
    ModelInstructor.fetchTrainingInstructor().then(response => {
      var info = response.data.filter(item => item !== null && item !== "");
      info.splice(0, 0, "Select instructor info");
      self.setState({
        instructorList: info
      });
    });
  }

  handleSubmit = e => {
    const { updateTrainingInfo, training } = this.props;
    e.preventDefault();
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        this.setState({ visible: true });
        values["description"] = this.state.content
          ? this.state.content
          : training.description;
        values["showWeb"] = this.state.showWeb;
        values["isFree"] = this.state.isFree;
        values["videoUrl"] = this.state.trainingVideoUrl||undefined;
        values["type"] =  training.type
        console.log({values})
        updateTrainingInfo(values);
        // this.props.trainingUpdate(values);
      }
    });
  };
  onCategoryChange = value => {
    console.log({ value });
    var self = this;
    self.setState({ category: value });
  };

  onChange = current => {
    // this.setState({ current });
  };
  onClickViewModel = () => {
    console.log("onClickViewModel---");
    this.setState({ viewModel: true });
  };
  onClickModalSCORM=(viewModelSCORM)=>{
    this.setState({viewModelSCORM})
  }

  saveVisibility = value => {
    this.setState({
      visibility: value
    });
  };

  onImgClose = () => {
    const { training, steps } = this.state;
    training["coverImage"] = undefined;
    this.setState({ training });
    this.setState({
      coverImageUrl: undefined
    });
  };
  onVideoClose = () => {
    const { training, steps } = this.state;
    // training["videoUrl"] = undefined;
    this.setState({ training });
    this.setState({
      trainingVideoUrl: undefined
    });
  };
  onChangeShowWeb=(checked)=> {
    console.log(`switch to ${checked}`);
    this.setState({showWeb:checked})
  }
  render() {
    const { getFieldDecorator } = this.props.form;
    const { training, onClickBack, state , fileUploadProps} = this.props;
    const {uploadStatus} = this.state
    var accessToken = localStorage.getItem("accessToken");
    if (this.state.change) {
      this.setState({
        training: training,
        isFree: training.isFree,
        change: false
      });
    }
    const { trainingId } = state;
    const formItemLayout = {
      labelAlign: "left",
      labelCol: {
        xs: { span: 24 },
        sm: { span: 24 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 24 }
      }
    };
    const tailFormItemLayout = {
      wrapperCol: {
        xs: {
          span: 24,
          offset: 0
        },
        sm: {
          span: 16,
          offset: 8
        }
      }
    };

    const beforeUploadVideoUpload = file => { 
      const isVideo = 
        file.type === ".mov" ||
        file.type === ".m4v" ||
        file.type === ".mpg" ||
        file.type === ".mp4" ||
        file.type === ".avi" || 
        file.type === "video/mp4";
      if (!isVideo) {
        message.error("You can only upload video file!");
        return isVideo;
      } 
      return isVideo;
    };
    const descriptionModelProps = {
      onSubmit: () => {

        training['description']= this.state.content
        ? this.state.content
        : training.description;
        this.setState({
          content: this.state.content
            ? this.state.content
            : training.description,
            training
        });
        this.setState({ viewModel: false });
      },
      receiveHtml: content => {
        console.log({ content });
        if (content !== "<p >Description</p>") {
          this.setState({ content: content ? content : training.description });
        }  
        // this.setState({content})
      },
      description: training.description ? training.description : "Description",
      modalVisible: this.state.viewModel,
      handleCancel: () => {
        // this.child.close();

        this.setState({ content: training.description });
        this.setState({ viewModel: false });
      }
    };
    const SCORMPackageModalProps={
      modalVisible: this.state.viewModelSCORM,
      handleCancel: () => { 
        this.setState({ viewModelSCORM: false });
      },
      fileUploadProps:fileUploadProps
    }

    var that = this;
    const stepsUploadVideoProps = {
      uploadProps: {
        name: "file",
        multiple: false,
        action:
        `${apiUrl}${"api/training/media/upload"}?_id=${
          training._id
        }&type=trainingVideo`, 
        headers: {
          Authorization: `Bearer ${accessToken}`
        },
        accept: ".mov,.m4v,.mpg,.mp4,.avi,video/mp4",
        beforeUpload: beforeUploadVideoUpload,
        onChange(info) {
          const { status, response } = info.file;
          if (status !== "uploading") {
          }
          if (status === "done") {
            if (!response.error && response.status === 200) {  
              that.setState({
                videoType:"pc",
                trainingVideoUrl:response.data 
              });
            } else {
               
            }
          } else if (status === "error") {
            message.error("Uploading failed. Please try again");
            that.setState({ loader: false });
          } else {
            that.setState({ loader: false });
          }
        }
      },
      type:this.state.videoType||training.type,
      trainingVideoUrl: this.state.trainingVideoUrl,//||training.videoUrl,
      saveVisibility: this.saveVisibility,
      onUploadVideoClose: this.onVideoClose,
      state: this.state, 
    };
    return (
      <div>
        <Form {...formItemLayout} onSubmit={this.handleSubmit}>
          <Row className=" gx-p-5">
            <Col xl={12} lg={12} md={12} sm={24} xs={24}>
              <Form.Item label="Title">
                {getFieldDecorator("name", {
                  initialValue: training.name,
                  rules: [
                    {
                      required: true,
                      // pattern: /^[a-zA-Z0-9_\-,]/,
                      message: "Please input title for training!"
                    }
                  ]
                })(<Input />)}
              </Form.Item>
              <Form.Item label="Short Description">
                {getFieldDecorator("shortDescription", {
                  initialValue: training.shortDescription,
                  rules: [
                    {
                      required: true,
                      // pattern: /^[a-zA-Z0-9_\-,]/,
                      message: "Please input short description for training!"
                    }
                  ]
                })(
                  <TextArea
                    className={styles.textAreaStyle}
                    rows={6}
                    placeholder="Short description"
                  />
                )}
              </Form.Item>
           
              <Form.Item  >
                <Button type="info" onClick={this.onClickViewModel}>
                  {" "}
                  {training.description
                    ? "Update description"
                    : "Add description"}{" "}
                </Button>
                {/* <Button type="info" onClick={()=>this.onClickModalSCORM(true)} style={{float:"right"}}>
                   {"Update SCORM Package" }
                </Button> */}
              
              </Form.Item>
                  {/* AuthorName */}
                  <Form.Item label="Author Name">
                {getFieldDecorator("authorName", {
                  initialValue: training.authorName,
                  rules: [
                    {
                      required: true,
                      // pattern: /^[a-zA-Z0-9_\-,]/,
                      message: "Please input author name for training!"
                    }
                  ]
                })(<Input />)}
              </Form.Item>
              {/* Training duration */}
              <Form.Item label="Training Duration in seconds">
                {getFieldDecorator("duration", {
                  initialValue: training.duration,
                  rules: [
                    {
                      required: true,
                      message: " "
                    }
                  ]
                })(<Input type="number" min="0" />)}
              </Form.Item>
             
           

              <Form.Item label="Training Price " >
                {getFieldDecorator("price", {
                  initialValue: training.price,
                  rules: [
                    {
                      required: true,
                      message: " "
                    }
                  ]
                })(<Input type="number" min="0" />)}
              </Form.Item>
              {/* Credit resources */}
              <Form.Item
                label={  "Credit resources"
                }
                style={{ marginBottom:0}}
              >
                {getFieldDecorator("credits", {
                  initialValue: training.credits,
                  rules: [
                    {
                      required: false,
                      pattern: /^[a-zA-Z0-9_\-.,]/,
                      message: "Only (-,.) Special characters are allowed"
                    }
                  ]
                })(
                  <Input
                    // rows={3}
                    // onChange={saveValue}
                  />
                )}
              </Form.Item>
             {/* Credit resources */}
                <Form.Item>
                <Checkbox
                  checked={this.state.isFree}
                  onChange={() => {
                    this.state.isFree
                      ? this.setState({ isFree: false })
                      : this.setState({ isFree: true });
                  }}
                >
                  Free
                </Checkbox> 
              </Form.Item>
            </Col>
            <Col xl={12} lg={12} md={12} sm={24} xs={24}>
              {/* Add Instructor */}
              <Form.Item
                  label={
                    <span>
                      {"Training video for mobile"} 
                    </span>
                  }
                >
              <UploadVideo {...stepsUploadVideoProps}/>

              </Form.Item>
              <Row  >
              <Col xl={10} lg={10} md={10} sm={24} xs={24}>
            <Form.Item
                  label={
                    <span>
                      {"Show training on web portal"}
                      <Tooltip placement="rightBottom" title={training.type!== "scorm"?"Please upload SCORM package":this.state.showWeb?"Click to hide training on web portal":"Enable training on the web portal."}>
                        <span> </span>
                        <Icon
                          type="question-circle"
                          style={{
                            fontSize: "14px",
                            color: "#9e9e9e" ,
                             position: "relative",
                             top: "-2px"
                          }}
                        />
                      </Tooltip>
                    </span>
                  }
                >
                <Switch defaultChecked={this.state.showWeb} onChange={(e)=>this.onChangeShowWeb(e)}  disabled ={training.type!== "scorm"?true:false}      data-uk-tooltip={`title: ${training.type!== "scorm"?"Please upload SCORM package":this.state.showWeb?"Click to hide training on web portal":"Enable training on the web portal."}   ; delay: 300 ; pos: top ;animation:	uk-animation-slide-bottom-small`}
                     >  <Tooltip placement="rightBottom" title={training.type!== "scorm"?"Please upload SCORM package":this.state.showWeb?"Click to hide training on web portal":"Enable training on the web portal."}>
                     <span> </span>
                     <Icon
                       type="question-circle"
                       style={{
                         fontSize: "14px",
                         color: "#9e9e9e" ,
                          position: "relative",
                          top: "-2px"
                       }}
                     />
                   </Tooltip></Switch>
                </Form.Item>
              
              </Col>
            <Col xl={14} lg={14} md={14} sm={24} xs={24} style={{paddingTop:"30px"}}>
            <Form.Item  >
                   {training.type!== "scorm" ? 
                <div  >
                <Upload {...fileUploadProps}>
                  <Button type="info" style={{ width: "100%" }} >
                    Upload SCORM Package
                  </Button>
                </Upload>

                </div>: <div  style={{  }}> 
                  <Button type="info" style={{ width: "100%" }} disabled >
                    Upload SCORM Package
                  </Button> 

                </div>} 
              </Form.Item>
              
              </Col>

          
              </Row>
             {
                // <Form.Item
                //   label={
                //     <span>
                //       {"Instructor"}
                //       {/* <Tooltip placement="rightBottom" title=" ">
                //         <span> </span>
                //         <Icon
                //           type="question-circle"
                //           style={{
                //             fontSize: "10px",
                //             color: "#9e9e9e"
                //           }}
                //         />
                //       </Tooltip> */}
                //     </span>
                //   }
                // >
                //   {getFieldDecorator("instructorId", {
                //     initialValue: training.instructorId
                //       ? training.instructorId._id
                //         ? training.instructorId._id
                //         : training.instructorId
                //       : training.instructorId,
                //     rules: [
                //       {
                //         required: true,
                //         message: "Please select instructor info"
                //       }
                //     ]
                //   })(
                //     <Select
                //       style={{
                //         color: "#282828"
                //       }}
                //       placeholder="Select instructor"
                //     >
                //       {this.state.instructorList.map((item, index) => {
                //         return (
                //           <Option
                //             key={"instructor" + index}
                //             style={{
                //               color: "#282828"
                //             }}
                //             value={item._id}
                //           >
                //             {item.name}
                //           </Option>
                //         );
                //       })}
                //     </Select>
                //   )}
                // </Form.Item>
              
              }
              {/* visibility */}
              <Form.Item
                label={
                  <span>
                    {"Visibility"}
                    {/* <Tooltip
                      placement="rightBottom"
                      title=" "
                    >
                      <span> </span>
                      <Icon
                        type="question-circle"
                        style={{
                          fontSize: "10px",
                          color: "#9e9e9e"
                        }}
                      />
                    </Tooltip> */}
                  </span>
                }
              >
                {getFieldDecorator("visibility", {
                  initialValue: training.visibility,
                  rules: [
                    {
                      required: true,
                      message: "Please select visibility"
                    }
                  ]
                })(
                  <Select placeholder="Select Visibility">
                    <Option
                      style={{
                        color: "#282828"
                      }}
                      value="0"
                    >
                      Private
                    </Option>
                    <Option
                      style={{
                        color: "#282828"
                      }}
                      value="1"
                    >
                      Organization
                    </Option>
                    <Option
                      style={{
                        color: "#282828"
                      }}
                      value="2"
                    >
                      Public
                    </Option>
                  </Select>
                )}
              </Form.Item>

              {/* Category */}
              {
                <Form.Item
                  label={
                    <span>
                      {"Category"}
                      {/* <Tooltip placement="rightBottom" title=" ">
                        <span> </span>
                        <Icon
                          type="question-circle"
                          style={{
                            fontSize: "10px",
                            color: "#9e9e9e"
                          }}
                        />
                      </Tooltip> */}
                    </span>
                  }
                >
                  {getFieldDecorator("trainingType", {
                    initialValue: training.trainingType,
                    rules: [
                      {
                        required: true,
                        message: "Please select category"
                      }
                    ]
                  })(
                    <Select
                      onChange={this.onCategoryChange}
                      style={{
                        color: "#282828"
                      }}
                      placeholder="Select Categories"
                      // onChange={saveVisibility}
                    >
                      <Option
                        style={{
                          color: "#282828"
                        }}
                        value={1}
                      >
                        Premium
                      </Option>
                      <Option
                        style={{
                          color: "#282828"
                        }}
                        value={2}
                      >
                        Standard
                      </Option>
                      <Option
                        style={{
                          color: "#282828"
                        }}
                        value={3}
                      >
                        CSCU Video
                      </Option>
                      <Option
                        style={{
                          color: "#282828"
                        }}
                        value={4}
                      >
                        Practice
                      </Option>
                    </Select>
                  )}
                </Form.Item>
              }

{
                <Form.Item
                  label={
                    <span>
                      {"Language"} 
                    </span>
                  }
                >
                  {getFieldDecorator("language", {
                    initialValue: training.language,
                    rules: [
                      {
                        // required: true,
                        message: "Please select language"
                      }
                    ]
                  })(
                    <Select
                      style={{
                        color: "#282828"
                      }}
                      placeholder="Select language"
                      // onChange={saveVisibility}
                    >
                      {this.state.languageList.map((item, index) => {
                        return (
                          <Option
                            key={"Sub Categories" + index}
                            style={{
                              color: "#282828"
                            }}
                            value={item.value}
                          >
                            {item.label}
                          </Option>
                        );
                      })}
                    </Select>
                  )}
                </Form.Item>
              }
              {this.state.category === 4 || training.category === 4 ? (
                <Form.Item
                  label={
                    <span>
                      {"Content Type"}
                      {/* <Tooltip placement="rightBottom" title=" ">
                        <span> </span>
                        <Icon
                          type="question-circle"
                          style={{
                            fontSize: "10px",
                            color: "#9e9e9e"
                          }}
                        />
                      </Tooltip> */}
                    </span>
                  }
                >
                  {getFieldDecorator("contentType", {
                    initialValue: training.contentType,
                    rules: [
                      {
                        required: true,
                        message: "Please select content type"
                      }
                    ]
                  })(
                    <Select
                      // onChange={this.onCategoryChange}
                      style={{
                        color: "#282828"
                      }}
                      placeholder="Select Content Type"
                      // onChange={saveVisibility}
                    >
                      <Option
                        style={{
                          color: "#282828"
                        }}
                        value="vedio"
                      >
                        Vedio
                      </Option>
                      <Option
                        style={{
                          color: "#282828"
                        }}
                        value="quiz"
                      >
                        Quiz
                      </Option>
                    </Select>
                  )}
                </Form.Item>
              ) : null}

              {/* Sub Category */}
              {
                <Form.Item
                  label={
                    <span>
                      {"Sub Category"}
                      {/* <Tooltip placement="rightBottom" title=" ">
                        <span> </span>
                        <Icon
                          type="question-circle"
                          style={{
                            fontSize: "10px",
                            color: "#9e9e9e"
                          }}
                        />
                      </Tooltip> */}
                    </span>
                  }
                >
                  {getFieldDecorator("trainingSubType", {
                    initialValue: training.trainingSubType,
                    rules: [
                      {
                        required: true,
                        message: "Please select sub category"
                      }
                    ]
                  })(
                    <Select
                      style={{
                        color: "#282828"
                      }}
                      placeholder="Select Sub Categories"
                      // onChange={saveVisibility}
                    >
                      {this.state.topicsList.map((item, index) => {
                        return (
                          <Option
                            key={"Sub Categories" + index}
                            style={{
                              color: "#282828"
                            }}
                            value={item}
                          >
                            {item}
                          </Option>
                        );
                      })}
                    </Select>
                  )}
                </Form.Item>
              }
              {/* Levels */}
              <Form.Item
                label={
                  <span>
                    {"Levels"}
                    {/* <Tooltip placement="rightBottom" title="  ">
                      <span> </span>
                      <Icon
                        type="question-circle"
                        style={{
                          fontSize: "10px",
                          color: "#9e9e9e"
                        }}
                      />
                    </Tooltip> */}
                  </span>
                }
              >
                {getFieldDecorator("level", {
                  initialValue: training.level,
                  rules: [
                    {
                      required: true,
                      message: "Please select levels"
                    }
                  ]
                })(
                  <Select
                    placeholder="Select Levels"
                    // onChange={saveVisibility}
                  >
                    <Option
                      style={{
                        color: "#282828"
                      }}
                      value="Beginner"
                    >
                      Beginner
                    </Option>
                    <Option
                      style={{
                        color: "#282828"
                      }}
                      value="Intermediate"
                    >
                      Intermediate
                    </Option>
                    <Option
                      style={{
                        color: "#282828"
                      }}
                      value="Advanced"
                    >
                      Advanced
                    </Option>
                    <Option
                      style={{
                        color: "#282828"
                      }}
                      value="Expert"
                    >
                      Expert
                    </Option>
                    {/* levels beginner intermediate advanced expert  */}
                  </Select>
                )}
              </Form.Item>
            
              <Form.Item {...tailFormItemLayout} style={{float: "right", marginTop:52, marginBottom:0}}>
                <Button type="info" htmlType="submit">
                  {trainingId ? "Update" : "Submit"}
                </Button>
              </Form.Item>
            </Col>
          </Row>
        
       
        </Form>
{ this.state.viewModelSCORM &&
  <ViewSCORMPackageModal {...SCORMPackageModalProps} />
}
        {this.state.viewModel && (
          <ViewModalDescription {...descriptionModelProps} />
        )}
      </div>
    );
  }
}

export default Form.create()(TrainingForm);
