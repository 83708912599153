import React from "react";
import "./index.less";
import { Calendar, Badge } from "antd";
import TopNavbar from "./../components/TopNavbar";
import moment from "moment";
import { Link } from "react-router-dom";
import Model from "./model";
import CircularProgress from "components/CircularProgress/index";

class CalendarView extends React.PureComponent {
  state = {
    trainings: [],
    loader: false,
    selectedDate: moment()
  };

  componentWillMount() {
    this.getTrainings();
  }

  getTrainings(data) {
    this.setState({ loader: true });
    Model.getTrainings(data).then(response => {
      console.log({ response });
      if (!response.error) {
        var { trainings } = this.state;
        trainings = response.data;
        this.setState({
          trainings,
          loader: false
        });
      } else {
        this.setState({
          loader: false
        });
      }
    });
  }

  onPanelChange = (value, mode) => {
    console.log(value, mode);
    this.setState({ selectedDate: value });
    this.getTrainings(value.toDate());
  };

  getListData = value => {
    let listData = [];
    //expireAt
    (this.state.trainings || []).map(training => {
      if (
        moment(training.assignedAt).format("D") == value.date() &&
        moment(training.assignedAt).format("M") == value.month() + 1
      ) {
        //expireAt
        
        if (listData.length == 0)
          listData = [
            {
              type:  moment().isAfter(training.campaignId.expiryDate)?"error":"success",
              content: training.campaignId.name,
              id: training.campaignId._id
            }
          ];
        else
          listData.push({
            type:  moment().isAfter(training.campaignId.expiryDate)?"error":"success",
            content: training.campaignId.name,
            id: training.campaignId._id
          });
      }
    });
    return listData || [];
  };

  dateCellRender = value => {
    const listData = this.getListData(value);
    return (
      <ul className="events">
        {listData.map(item => (
          <li key={item.content} className={item.type}>
            <Link
              to={{
                pathname: "/trainee/course-intro/" + item.id + "/Assigned"
              }}
            >
              <Badge status={item.type} text={item.content} />
            </Link>
          </li>
        ))}
      </ul>
    );
  };

  getMonthData = value => {
    if (value.month() === 8) {
      return 1394;
    }
  };

  monthCellRender = value => {
    const num = this.getMonthData(value);
    return num ? (
      <div className="notes-month">
        <section>{num}</section>
        <span>Backlog number</span>
      </div>
    ) : null;
  };

  render() {
    const { loader, selectedDate } = this.state;
    if (loader) {
      return (
        <div>
          <TopNavbar key="topNavbarKey" />
          <CircularProgress></CircularProgress>
        </div>
      );
    }

    return (
      <div>
        <TopNavbar key="topNavbarKey" />
        <div className="uk-container uk-margin uk-align-center">
          <h3
            className="uk-margin-small-bottom  "
            style={{ textAlign: "center" }}
          >
            {"Upcoming Training"}
          </h3>
        </div>
        <div className="uk-container uk-margin-medium-top">
          <div className="uk-card " style={{ marginBottom: "50px" }}>
            <div
              className="uk-card-default uk-card-hover uk-card-small Course-card uk-inline-clip uk-transition-toggle"
              tabindex="0"
              id="calendar"
            >
              <Calendar
                key="calendar"
                value={selectedDate}
                onPanelChange={this.onPanelChange}
                dateCellRender={this.dateCellRender}
                // monthCellRender={this.monthCellRender}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default CalendarView;
