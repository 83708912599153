import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import axios from "axios";

import {
  GET_TRAINING,
  GET_All_TRAINING,
  UPDATE_TRAINING,
  UPDATE_ON_TRAINING,
  DELETE_TRAINING,
  ON_ADD_TRAINING,
  GET_TRAINEE_TRAINING,
  GET_TRAINEE_ALL_TRAINING,
  ACTION_BOOKMARKS_TRAINING,
  GET_BOOKMARKS_TRAINING
  // SHOW_MESSAGE,
} from "../../constants/ActionTypes";
import {
  trainingUpdateSuccess,
  getAllTrainingSuccess,
  getTrainingSuccess,
  trainingOnUpdateSuccess,
  trainingAddSuccess,
  deleteTrainingSuccess,
  getTraineeTrainingSuccess,
  getTraineeAllTrainingSuccess,
  actionBookmarksTrainingSuccess,
  getBookmarksTrainingSuccess,
  showMessage,
  hideMessage
  // getAllTraining
} from "../actions/Training";

import { api, apiUrl } from "util/config";
import store from "store";

const {
  queryUpdateTraining,
  queryAllTrainings,
  queryTrainings,
  queryTraineeTraining
} = api;

if (typeof localStorage.getItem("accessToken") !== undefined) {
  axios.defaults.headers.common["Authorization"] =
    "Bearer " + localStorage.getItem("accessToken");
}
const actionBookmarksTrainingRequest = async payload =>
  await axios.get(
    apiUrl + "api/bookmarks/" + payload.trainingId + "/" + payload.action,
    {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("accessToken")
      }
    }
  );
const getBookmarksTrainingRequest = async paramsObj =>
  await axios.get(apiUrl + "api/mybookmarks", {
    headers: {
      Authorization: "Bearer " + localStorage.getItem("accessToken")
    }
  });
const getAllTraineeTrainingRequest = async paramsObj =>
  await axios
    .get(apiUrl + queryTraineeTraining, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("accessToken")
      },
      params: paramsObj
    })
    .then(response => response)
    .catch(error => error);

const updateTrainingRequest = async training =>
  await axios
    .post(apiUrl + queryUpdateTraining, training, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("accessToken")
      }
    })
    .then(response => response)
    .catch(error => error);

const getTrainingRequest = async payload =>
  await axios
    .get(apiUrl + queryAllTrainings, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("accessToken")
      },
      params: payload
    })
    .then(response => response)
    .catch(error => error);

const deleteTrainingRequest = async trainingId =>
  await axios
    .delete(apiUrl + queryTrainings + "/" + trainingId, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("accessToken")
      }
    })
    .then(response => response)
    .catch(error => error);

const getAllTrainingRequest = async payload =>
  await axios
    .get(apiUrl + queryAllTrainings, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("accessToken")
      },
      params: payload
    })
    .then(response => response)
    .catch(error => error);

function* actionBookmarksTraining({ payload }) {
  yield put(hideMessage());
  // console.log({payload})
  const { trainingInfo } = payload;
  try {
    const responseData = yield call(actionBookmarksTrainingRequest, payload);
    if (responseData.message) {
      yield put(showMessage({ error: responseData.message }));
    } else {
      if (responseData.data.data) {
        trainingInfo["bookmark"] = payload.action === "add" ? true : false;
        yield put(showMessage({ success: responseData.data.userMessage }));
        yield put(hideMessage());
        yield put(actionBookmarksTrainingSuccess(trainingInfo));
      }
    }
  } catch (error) {
    yield put(showMessage({ error }));
  }
}

function* getBookmarksTraining({ payload }) {
  const { page } = payload;
  yield put(hideMessage());
  try {
    const responseData = yield call(getBookmarksTrainingRequest, payload);
    if (responseData.message) {
      yield put(showMessage({ error: responseData.message }));
    } else {
      if (responseData.data.data) {
        var pagination = {};
        pagination["page"] = page || 0;
        pagination["totalRecords"] = responseData.data.totalRecords;
        var result = {};
        result["data"] = responseData.data.data;
        result["pagination"] = pagination;

        yield put(getBookmarksTrainingSuccess(result));
      }
    }
  } catch (error) {
    yield put(showMessage({ error }));
  }
}

function* getAllTraineeTraining({ payload }) {
  const { page } = payload;
  yield put(hideMessage());
  try {
    const responseData = yield call(getAllTraineeTrainingRequest, payload);
    if (responseData.message) {
      yield put(showMessage({ error: responseData.message }));
    } else {
      if (responseData.data.data) {
        const trainingInfo = responseData.data.data; //getTraineeAllTrainingSuccess
        var pagination = {};
        pagination["page"] = page || 0;
        pagination["totalRecords"] = responseData.data.totalRecords;
        var result = {};
        result["data"] = responseData.data.data;
        result["pagination"] = pagination;
        result['totalTraining'] = responseData.data.totalTraining;
        result['totalUserCount'] = responseData.data.totalUserCount;
        result['totalDiscussion'] = responseData.data.totalDiscussion;
        console.log({ result });
        if (!page) {
          //assignedTraining
          store.set("assignedTraining", responseData.data.data);
        }
        yield put(getTraineeAllTrainingSuccess(result));
      }
    }
  } catch (error) {
    yield put(showMessage({ error }));
  }
}

function* getTraineeTraining({ payload }) {
  yield put(hideMessage());
  try {
    const responseData = yield call(getAllTraineeTrainingRequest, payload);
    if (responseData.message) {
      yield put(showMessage({ error: responseData.message }));
    } else {
      if (responseData.data.data) {
        const trainingInfo = responseData.data.data;
        yield put(getTraineeTrainingSuccess(trainingInfo));
      }
    }
  } catch (error) {
    yield put(showMessage({ error }));
  }
}

function* deleteTraining({ payload }) {
  const trainingId = payload.trainingId;
  const index = payload.index;
  var allTraining = payload.allTraining;
  yield put(hideMessage());
  try {
    const responseData = yield call(deleteTrainingRequest, trainingId);
    if (responseData.message) {
      yield put(showMessage({ error: responseData.message }));
    } else {
      if (responseData.data) {
        allTraining.splice(index, 1);
        yield put(deleteTrainingSuccess(allTraining));
        yield put(showMessage({ success: responseData.data.userMessage }));
      }
    }
  } catch (error) {
    yield put(showMessage({ error }));
  }
}

function* getTraining({ payload }) {
  // const trainingId = payload.trainingId;
  yield put(hideMessage());
  try {
    const responseData = yield call(getTrainingRequest, payload);
    if (responseData.message) {
      yield put(showMessage({ error: responseData.message }));
    } else {
      if (responseData.data.data) {
        const trainingInfo = responseData.data.data;
        yield put(getTrainingSuccess(trainingInfo));
      }
    }
  } catch (error) {
    yield put(showMessage({ error }));
  }
}

function* getAllTraining({ payload }) {
  // const trainingId = payload.trainingId;
  const { page } = payload;
  yield put(hideMessage());
  try {
    const responseData = yield call(getAllTrainingRequest, payload);
    if (responseData.message) {
      yield put(showMessage({ error: responseData.message }));
    } else {
      if (responseData.data.data) {
        var pagination = {};
        pagination["page"] = page || 0;
        pagination["totalRecords"] = responseData.data.totalRecords;
        var result = {};
        var filtered = responseData.data.data.filter(data => {
          console.log({ data });
          return data;
        });
        console.log({ filtered }, filtered.length);
        result["data"] = filtered;
        result["pagination"] = pagination;
        yield put(getAllTrainingSuccess(result));
      }
    }
  } catch (error) {
    yield put(showMessage({ error }));
  }
}

function* updateTraining({ payload }) {
  const training = payload.training;
  yield put(hideMessage());
  try {
    const responseData = yield call(updateTrainingRequest, training);
    if (responseData.message) {
      yield put(showMessage({ error: responseData.message }));
    } else {
      if (responseData.data.data) {
        const trainingInfo = responseData.data.data;
        trainingInfo["steps"] = 2;
        // yield put(showMessage(error));
        yield put(trainingUpdateSuccess(trainingInfo));
        yield put(showMessage({ success: responseData.data.userMessage }));
      }
    }
  } catch (error) {
    yield put(showMessage({ error }));
  }
}

export function* bookmarksTrainingAction() {
  yield takeEvery(ACTION_BOOKMARKS_TRAINING, actionBookmarksTraining);
}
export function* bookmarksTrainingGet() {
  yield takeEvery(GET_BOOKMARKS_TRAINING, getBookmarksTraining);
}
export function* traineeTrainingGet() {
  yield takeEvery(GET_TRAINEE_TRAINING, getTraineeTraining);
}
export function* traineeTrainingAllGet() {
  yield takeEvery(GET_TRAINEE_ALL_TRAINING, getAllTraineeTraining);
}

function* addTraining({ payload }) {
  const training = payload;
  yield put(trainingAddSuccess(training));
}

function* updateOnTraining({ payload }) {
  const training = payload.training;
  yield put(trainingOnUpdateSuccess(training));
}

export function* trainingUpdate() {
  yield takeEvery(UPDATE_TRAINING, updateTraining);
}
export function* trainingDelete() {
  yield takeEvery(DELETE_TRAINING, deleteTraining);
}

export function* trainingAdd() {
  yield takeEvery(ON_ADD_TRAINING, addTraining);
}
export function* trainingOnUpdate() {
  yield takeEvery(UPDATE_ON_TRAINING, updateOnTraining);
}
export function* trainingGet() {
  yield takeEvery(GET_TRAINING, getTraining);
}

export function* trainingAllGet() {
  yield takeEvery(GET_All_TRAINING, getAllTraining);
}

export default function* rootSaga() {
  yield all([
    fork(bookmarksTrainingAction),
    fork(bookmarksTrainingGet),
    fork(traineeTrainingGet),
    fork(traineeTrainingAllGet),
    fork(trainingGet),
    fork(trainingAllGet),
    fork(trainingUpdate),
    fork(trainingAdd),
    fork(trainingDelete),
    fork(trainingOnUpdate)
  ]);
}
