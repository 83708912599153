import React, { Component } from "react";
import { connect } from "react-redux";
import { Avatar, Popover } from "antd";
import { userSignOut } from "appRedux/actions/Auth";
import { withRouter } from "react-router-dom";
import { Link } from "react-router-dom";
import { imagePrefixUrl } from "util/config";
import { apiUrl } from "util/config";
import store from "store";
import moment from "moment";
import axios from "axios";
import { imageMediaUrl } from "util/config";
import Model from "./../../myCart/model";
import LogoutModal from "./../../../../containers/TopNavbar/logoutModal";
import "./../CatagoryInfo/index.less";
// import { useTranslation, Trans, withTranslation } from "react-i18next";
import {
  switchLanguage
} from "appRedux/actions/Setting";
import languageData from "./../../../../containers/Topbar/languageData";
import CustomScrollbars from "util/CustomScrollbars";
import IntlMessages from "util/IntlMessages";



axios.defaults.headers.common["Authorization"] = `Bearer ${store.get("user") ? store.get("user").accessToken : ""}`;
const languageDatas = [
  {
    languageId: 'english',
    locale: 'en',
    name: 'English',
    icon: 'us'
  },
  {
    languageId: 'saudi-arabia',
    locale: 'ar',
    name: 'Arabic',
    icon: 'sa'
  }
];

class TopNavbar extends Component {
  state = {
    yourCourses: true,
    toggleStatus: 1,
    myCart: [],
    userInfo: {},
    logoutModalVisible: false,
    notificationCount: 0,
    callStatus: true,
    selectedLanguage:{
      languageId: 'english',
      locale: 'en',
      name: 'English',
      icon: 'us'
    }
  };

  componentWillMount() {
    console.log(store.get("user"),"store get 1")
    this.setState({ myCart: store.get("myCart") });
    this.setState({ userInfo: store.get("user") });
    this.setState({
      notificationCount: parseInt(store.get("notificationCount")),
    });
    if (store.get("user") && store.get("user").trainee) {
      this.fatchNotification();
      Model.getCart().then((response) => {
        if (!response.error) {
          store.set("myCart", response.data);
          this.setState({ myCart: response.data });
        }
      });
    }
  }
  fatchNotification() {
    console.log("this.state.callStatus", this.state.callStatus);
    if (this.state.callStatus) {
      this.setState({ callStatus: false });
      Model.fetchNotification().then((response) => {
        if (!response.error) {
          store.set("notifications", response.data);
          this.setState({
            notification: response.data,
          });
        }
        this.setState({ callStatus: true });
      });
    }
  }
  componentDidUpdate() {
    console.log(store.get("user"),"store get 12")

  if(this.state.userInfo.userName && this.state.userInfo.userName != store.get("user").userName)
      this.setState({ userInfo: store.get("user") });


    // var self = this;
    // const { userInfo, notificationCount } = self.state;
    // document.addEventListener("myCart", function(e) {
    //   self.setState({ myCart: e.detail.storeTraining });
    // });
    // document.addEventListener("notification", function(e) {
    //   var status = e.detail.status;
    //   // console.log("e.detail", e.detail);
    //   if (status === 0) {
    //     self.fatchNotification();
    //   }
    // });
    // document.addEventListener("userInfo", function(e) {
    //   var userInfo1 = e.detail.userInfo;
    //   if (userInfo1.notificationCount != notificationCount) {
    //     self.fatchNotification();
    //   }
    //   self.setState({
    //     userInfo: userInfo1,
    //     notificationCount: userInfo1.notificationCount
    //   });
    // });
  }
  componentDidMount() {
    var self = this;
    const { userInfo, notificationCount } = self.state;
    document.addEventListener("myCart", function (e) {
      self.setState({ myCart: e.detail.storeTraining });
    });
    document.addEventListener("notification", function (e) {
      var status = e.detail.status;
      console.log("e.detail", e.detail);

      if (status === 0) {
        self.fatchNotification();
      }
    });
    document.addEventListener("userInfo", function (e) {
      console.log("userInfo--", userInfo)
      var userInfo1 = e.detail.userInfo;
      if (userInfo1.notificationCount != notificationCount) {
        self.fatchNotification();
      }
      self.setState({
        userInfo: userInfo1,
        notificationCount: userInfo1.notificationCount,
      });
    });
  }

  onNotificationClick = (notification, index) => {
    var self = this;
    //console.log({ notification });
    axios
      .get(apiUrl + "api/traineeNotification/clear?notification=" + notification._id, {
        notification: notification,
      }, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("accessToken")
        }
      })
      .then(
        (response) => {
          //console.log(response);
          if (!response.data.data.error) {
            // store.set("notifications",[])
            console.log("response.data", response.data);
            // status: "read";
            var notifications = store.get("notifications");
            notification.status = "read";
            notifications[index] = notification;
            self.forceUpdate();
            store.set("notifications", notifications);
            store.set("notificationCount", response.data.data);
            self.setState({
              notificationCount: parseInt(response.data.data),
            });
          }
        },
        (error) => {
          //console.log(error);
        }
      );
    this.props.history.push(notification.action);
  };
  getFields(input, field) {
    var output = [];
    for (var i = 0; i < input.length; ++i) output.push(input[i][field]);
    return output;
  }

  clearAllNotification = (notifications) => {
    var self = this;
    console.log({ notifications });
    // notifications.filter(notification => notification._id);
    notifications = this.getFields(notifications, "_id");
    console.log({ notifications });
    axios
      .post(apiUrl + "api/traineeNotification/clear", {

        notifications: notifications,
      }, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("accessToken")
        }
      })
      .then(
        (response) => {
          console.log(response);
          if (!response.data.error) {
            console.log({ response });
            store.set("notifications", []);
            self.forceUpdate();
            store.set("notificationCount", 0);
            self.setState({
              notificationCount: 0,
            });
            console.log("self.setState", self.state);
          }
        },
        (error) => {
          //console.log(error);
        }
      );
  };
  onClickLogout = (action) => {
    console.log({ action });
    if (action == "logout") {
      this.props.userSignOut();
    }
  };

  changeLanguage = (e,val) => {
    console.log(e,val,"asasa")
    this.setState({selectedLanguage:val})
    this.props.switchLanguage(val)
  }

 languageMenu = () => (
    <CustomScrollbars className="gx-popover-lang-scroll">
      <ul className="gx-sub-popover">
        {languageDatas.map(language =>
          <li className="gx-media gx-pointer" key={JSON.stringify(language)} onClick={(e) => this.changeLanguage(e,language)
          }>
            <i className={`flag flag-24 gx-mr-2 flag-${language.icon}`}/>
            <span className="gx-language-text">{language.name}</span>
          </li>
        )}
      </ul>
    </CustomScrollbars>);

  render() {
    const { match } = this.props;

    //console.log({ myCartTraining });
    console.log(this.props, "this")


    var AssignedTraining = store.get("assignedTraining") || [];
    AssignedTraining = AssignedTraining.filter((t) => t.campaignId.trainingId !== null);
    var styleNav = {};
    if (
      match.path === "/trainee/profile" ||
      match.path === "/trainee/settings" ||
      match.path === "/trainee/calendar" ||
      match.path === "/trainee/your/training" ||
      match.path === "/trainee/result/:trainingId" ||
      match.path === "/trainee/cart"
    ) {
      styleNav = {
        position: "fixed",
        top: "0",
        height: "70px",
        left: "0px",
        width: "100%",
        background: "#021629", //"rgb(62, 71, 79)",
        boxShadow: "rgba(0, 0, 0, 0.14) 0px 5px 15px",
        zIndex: "99",
      };
    }
    var { userInfo, myCart } = this.state;
    var notifications = store.get("notifications") || [];
    console.log("notifications.length", notifications.length);
    const onClickYourCources = (url) => {
      this.props.history.push(url);
    };

    const logoutModalProps = {
      logoutModalVisible: this.state.logoutModalVisible,
      handleConfirm: () => {
        this.props.userSignOut();
      },
      handleCancel: () => {
        this.setState({ logoutModalVisible: false });
      },
    };
    console.log("userInfo.notificationCount", userInfo.notificationCount);
    var notificationCount = parseInt(store.get("notificationCount"));
    notificationCount = notificationCount === 0 ? -1 : notificationCount || 0;
    var self = this;
    console.log({ notificationCount })
    return (
      <div id="nav-id" style={styleNav}>
        <nav
          className="uk-navbar-transparent tm-mobile-header uk-animation-slide-top uk-position-z-index"
          data-uk-navbar
        //   style={styleNav}
        >
          {/* <!-- mobile icon for side nav on nav-mobile-->                  */}
          <span className=" tm-mobile-menu-icon" data-uk-toggle="target: #side-nav; cls: side-nav-active">
            <i className="fas fa-bars ic-icon-large"></i>
          </span>
          {/* <!-- mobile icon for user icon on nav-mobile -->                  */}
          <span
            className="uk-hidden@m tm-mobile-user-icon uk-align-right"
            data-uk-toggle="target: #tm-show-on-mobile-1; cls: tm-show-on-mobile-active "
          >
            <i className="far fa-user ic-icon-large"></i>
          </span>
          {/* <!-- mobile logo -->                  */}
          <a className="uk-hidden@m uk-logo"> OhPhish </a>
          <div className="uk-navbar-left uk-visible@m">
            {/* <a className="uk-navbar-item back-to-dashboard uk-button-text " href="#" data-uk-tooltip="title: back-to-dashboard ; delay: 700 ; pos: bottom-left ;animation:	uk-animation-scale-up">My Dashboard</a> 
                                <a className="uk-navbar-item back-to-dashboard uk-button-text " href="#">Browse All Courses</a>  */}
          </div>
          <div className="uk-navbar-right tm-show-on-mobile uk-flex-right" id="tm-show-on-mobile-1">
            {/* <!-- this will clouse after display user icon -->                      */}
            <span
              className="uk-hidden@m tm-mobile-user-close-icon uk-align-right"
              data-uk-toggle="target: #tm-show-on-mobile-1; cls: tm-show-on-mobile-active "
              style={{ position: "absolute", top: "0px" }}
            >
              <i className="fas fa-times ic-icon-large"></i>
            </span>
            <ul className=" uk-navbar-nav uk-flex-middle">
              {/* <li> 
                                        <a href="#modal-full" data-uk-toggle><i className="fas fa-search ic-icon-medium"></i></a> 
                                    </li>                          */}
              {/* <li>
               <Popover overlayClassName="gx-popover-horizantal" placement="bottomRight" content={this.languageMenu()}
                       trigger="click">
                <span className="gx-pointer gx-flex-row gx-align-items-center">
                  <i className={`flag flag-24 flag-${this.state.selectedLanguage.icon}`}/> 
                  <span className="gx-pl-2 gx-language-name" style={{color:"white"}}>{this.state.selectedLanguage.name}</span>
                  <i className="icon icon-chevron-down gx-pl-2"/>
                </span>
              </Popover>
              </li> */}

              <li>
                {/* <!-- your courses -->                              */}
                <a href="#">
                  {" "}
                  <i className="fas fa-play uk-hidden@m"></i> <span className="uk-visible@m"><IntlMessages
                id="topNav.courses"/> </span>{" "}
                </a>

                <div
                  data-uk-dropdown="mode : click; animation: uk-animation-slide-bottom-medium"
                  className="uk-dropdown border-radius-6  uk-dropdown-top-right tm-dropdown-large uk-padding-remove"
                  style={{ top: "70px !important" }}
                >
                  <div style={{ top: "70px !important" }}>
                    {/* <div
                    className="uk-child-width-1-1@s "
                    // className="uk-clearimport style from '../../../extensions/charts/recharts/radial/Components/style';
                    // fix"
                    >
                      <div className="uk-float-left">
                        <h5 className="uk-padding-small uk-margin-remove uk-text-bold  uk-text-left">
                          {" "}
                          Your Courses{" "}
                        </h5>
                      </div>
                      <div className="uk-float-right">
                        <i className="fas fa-check uk-align-right  uk-margin-remove uk-margin-remove-left  uk-padding-small uk-text-small"> Completed 3 / 5 </i> 
                      </div>
                    </div> */}
                    <hr className=" uk-margin-remove" />
                    {AssignedTraining && AssignedTraining.length > 0 ? (
                      <div className="uk-padding-smaluk-text-left ">
                        <div className="demo1" data-simplebar>
                          <div
                            className="uk-child-width-1-2@s  uk-grid-small uk-padding-small"
                            data-uk-scrollspy="target: > div; cls:uk-animation-slide-bottom-small; delay: 100 ;repeat: true"
                            data-uk-grid
                          >
                            {AssignedTraining &&
                              AssignedTraining.length > 0 &&
                              AssignedTraining.map((item, index) => {
                                //console.log({ item });
                                return (
                                  <div key={"top-nav-your-courses" + index}>
                                    <a
                                      onClick={() => {
                                        onClickYourCources(
                                          "/trainee/course-intro/" + item.campaignId._id + "/Assigned"
                                        );
                                      }}
                                      className="uk-link-reset uk-dropdown-close"
                                    >
                                      <div className="uk-padding-small uk-card-default">
                                        {/* <progress id="js-progressbar" className="uk-progress uk-margin-small-bottom" value="50" max="100"  style={{height: '7px'}}></progress>   */}
                                        <img
                                          src={
                                            item.campaignId.trainingId.coverImage
                                              ? `${imagePrefixUrl}${item.campaignId.trainingId.coverImage}`
                                              : require("assets/images/courses/cover_image.jpg")
                                          }
                                          className="uk-align-left  uk-margin-small-right uk-margin-small-bottom  uk-width-1-3"
                                        />
                                        {/* <img src={require("assets/images/courses/course-4.jpg")} className="uk-align-left  uk-margin-small-right uk-margin-small-bottom  uk-width-1-3" alt=""/>  */}
                                        <p
                                          className="uk-text-bold uk-margin-remove"
                                          data-uk-tooltip={
                                            "title: " +
                                            item.campaignId.trainingId.name +
                                            " ; delay: 400 ; pos: top ;animation:	uk-animation-scale-up"
                                          }
                                        >
                                          {item.campaignId.trainingId.name
                                            ? item.campaignId.trainingId.name.length > 18
                                              ? item.campaignId.trainingId.name.substring(0, 18) + ".."
                                              : item.campaignId.trainingId.name
                                            : " "}
                                        </p>
                                        <p
                                          className="uk-text-small uk-margin-remove"
                                          data-uk-tooltip={
                                            "title: " +
                                            item.campaignId.trainingId.authorName +
                                            " ; delay: 500 ; pos: top ;animation:	uk-animation-scale-up"
                                          }
                                        >
                                          {" "}
                                          by :{" "}
                                          {item.campaignId.trainingId.authorName
                                            ? item.campaignId.trainingId.authorName.length > 15
                                              ? item.campaignId.trainingId.authorName.substring(0, 15) + ".."
                                              : item.campaignId.trainingId.authorName
                                            : " "}{" "}
                                        </p>
                                      </div>
                                    </a>
                                  </div>
                                );
                              })}
                          </div>
                        </div>
                      </div>
                    ) : (
                        <div className=" uk-text-center uk-margin">
                          <div className="uk-text-center uk-margin" style={{ padding: "0 20%", marginTop: "50px" }}>
                            <img
                              className=" uk-margin"
                              src={require("assets/images/no_data_found.png")}
                              style={{ width: "50%" }}
                            />
                          </div>
                          <div className="uk-text-center uk-margin-top">
                            <h3 className="uk-text-center uk-margin-remove">Sorry!! No Data Found.</h3>
                          </div>
                          <div className=" uk-margin-bottom">
                            <p className="uk-text-center uk-margin-remove">Try somethings else.</p>
                          </div>
                        </div>
                      )}
                    <hr className=" uk-margin-remove" />
                    {AssignedTraining && AssignedTraining.length > 0 && (
                      <h5 className="  uk-padding-small uk-margin-remove uk-text-bold uk-text-center">
                        {/* <Link  to="/trainee/Assigned"  className="uk-dropdown-close uk-link-heading  " > See all 
                                                </Link>  */}
                        <a
                          className=" uk-link-heading uk-dropdown-close"
                          onClick={() => {
                            onClickYourCources("/trainee");
                          }}
                        >
                          {" "}
                          See all
                        </a>
                      </h5>
                    )}
                  </div>
                </div>
              </li>
              {/* <!-- Cart -->  */}
              {/* <li>
                {store.get("user").trial && (
                  <Link to="/trainee/cart">
                    <i className="fas fa-shopping-cart ic-icon-large"></i>{" "}
                    {myCart && myCart.length > 0 ? (
                      <span className="uk-badge uk-background-danger">{myCart.length}</span>
                    ) : (
                      ""
                    )}{" "}
                  </Link>
                )}
              </li> */}
              <li>
                {/* <!-- Notifications --> */}
                <a href="#">
                  <i className="fas fa-bell ic-icon-large"></i>{" "}
                  {notificationCount && notificationCount !== -1 && notificationCount !== 0 ? (
                    <span
                      className="uk-badge uk-background-danger"
                      style={{
                        padding: "0 2px",
                        marginTop: "-15px",
                        marginLeft: "-5px",
                      }}
                    >
                      {notificationCount !== "NaN" && notificationCount !== null ? notificationCount : ""}
                    </span>
                  ) : ""}
                </a>

                <div
                  data-uk-dropdown=" mode : hover; animation: uk-animation-slide-bottom-small"
                  className="uk-dropdown uk-dropdown-top-right  tm-dropdown-small border-radius-6 uk-padding-remove uk-box-shadow-large angle-top-right"
                >
                  <h5 className="uk-padding-small uk-margin-remove uk-text-bold  uk-text-left"><i> <IntlMessages
                id="topNav.notification"/></i> </h5>
                  <a
                    onClick={function () {
                      self.clearAllNotification(notifications);
                    }}
                    href="#"
                    className="uk-position-top-right uk-link-reset"
                  >
                    {" "}
                    <i className="fas fa-trash uk-align-right   uk-text-small uk-padding-small"><IntlMessages
                id="topNav.clearAll"/></i>
                  </a>
                  <hr className=" uk-margin-remove" />
                  <div id="notification_tab" className="uk-padding-smaluk-text-left  " style={{ height: 200 }}>
                    {/* <div id="notification_tab" className="uk-padding-smaluk-text-left uk-height-medium"> */}
                    <div data-simplebar>
                      <div
                        className="uk-padding-small"
                        data-uk-scrollspy="target: > div; cls:uk-animation-slide-bottom-small; delay: 100 ; repeat: true"
                      >
                        {notifications.map(function (notification, index) {
                          return (
                            <div
                              key={"notification" + index}
                              className={"uk-flex-middle uk-grid-small uk-dropdown-close"}
                              data-uk-grid
                              onClick={function () {
                                self.onNotificationClick(notification, index);
                              }}
                              style={{ visibility: "unset" }}
                            >
                              <div className={`uk-width-3-4 ${notification.status === "unread" ? "uk-text-bold" : ""}`}>
                                <p className="uk-margin-remove">{notification.notificationText}</p>
                                <p className="uk-margin-remove-top uk-text-small uk-text-muted">
                                  {" "}
                                  {moment(notification.createdAt).format("dddd, MMMM Do YYYY")}
                                </p>
                              </div>
                              <div className="uk-width-1-5 uk-flex-first">
                                <img
                                  src={require("assets/images/avatures/avature-4.png")}
                                  alt="Image"
                                  className="uk-border-circle"
                                />
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                </div>
              </li>
              <li>
                {/* <!-- User profile -->                              */}
                <a href="#">
                  <img
                    src={
                      userInfo && userInfo.profilePhoto && userInfo.profilePhoto !== ""
                        ? `${imageMediaUrl}${userInfo.profilePhoto}`
                        : require("assets/images/avatures/top_nav.png")
                    }
                    alt=""
                    className="uk-border-circle user-profile-tiny"
                  />
                </a>
                <div
                  data-uk-dropdown=" mode :  hover;animation: uk-animation-slide-right"
                  className="uk-dropdown  uk-dropdown-top-right  tm-dropdown-small-custom border-radius-6 angle-top-right"
                >
                  <div className="uk-grid-small uk-flex-middle uk-margin-small-bottom" data-uk-grid>
                    <div className="uk-width-1-4  uk-first-column">
                      <img
                        src={
                          userInfo && userInfo.profilePhoto && userInfo.profilePhoto !== ""
                            ? `${imageMediaUrl}${userInfo.profilePhoto}`
                            : require("assets/images/avatures/top_nav.png")
                        }
                        alt="Image"
                        className="uk-align-center uk-border-circle  user-profile-tiny"
                      />
                    </div>
                    <div className="uk-width-3-4">
                      <p className="uk-margin-remove-bottom uk-margin-small-top uk-text-bold">{userInfo.userName}</p>
                      <p className="uk-margin-remove-bottom uk-margin-small-top uk-text-bold"></p>
                      <div className="uk-margin-remove-bottom uk-margin-small-top uk-text-bold">
                        {userInfo && (userInfo.badges || []).length > 0 && userInfo.badges.map(function (badge, index) {
                          return (
                            <div key={"badge-key-" + index}>
                              <object
                                data={require("assets/images/badge.svg")}
                                type="image/svg+xml"
                                style={{ width: 20, color: "green" }}
                              ></object>
                              <div style={{ fontSize: 7 }}>{badge.name}</div>
                            </div>
                          );
                        })}
                      </div>
                      {/* <p className="uk-margin-remove-top uk-text-small uk-margin-small-bottom"> {`India`}</p>  */}
                    </div>
                  </div>
                  <ul className="uk-nav uk-dropdown-nav">
                    <li>
                      <Link to="/trainee/profile">
                        {" "}
                        <i className="fas fa-user uk-margin-small-right"></i> <IntlMessages
                id="topNav.profile"/> 
                      </Link>
                    </li>
                    {/* <li> 
                                                    <a href="#"> <i className="fas fa-envelope uk-margin-small-right"></i> Messages </a> 
                                                </li>                                      */}
                    {/* <li> 
                                                    <a href="#"> <i className="fas fa-share uk-margin-small-right"></i> Invite freind</a> 
                                                </li>                                      */}
                    {/* <li>
                      <Link to="/trainee/settings">
                        {" "}
                        <i className="fas fa-cog uk-margin-small-right"></i>{" "}
                        Settings
                      </Link>
                    </li> */}

                    <li className="uk-nav-divider"></li>
                    <li>
                      {/* <a onClick={() => this.props.userSignOut()}> <i className="fas fa-sign-out-alt uk-margin-small-right"></i> Log out</a>  */}

                      <a
                        onClick={() => {
                          this.setState({ logoutModalVisible: true });
                        }}
                      >
                        {" "}
                        <i className="fas fa-sign-out-alt uk-margin-small-right"></i><IntlMessages
                id="topNav.logout"/>  {" "}
                      </a>
                      {/* <div id="modal-Logout" data-uk-modal>
                        <div className="uk-modal-dialog">
                          <button
                            className="uk-modal-close-default uk-margin-small-top  uk-margin-small-right uk-light"
                            type="button"
                            data-uk-close
                          ></button>
                          <div className="uk-modal-header ">
                            <b className=" uk-text-medium"> Log out </b>
                          </div>
                          <div className="uk-modal-body">
                            <h5>
                              {" "}
                              Are you sure you want to logout from EC-Council Aware ?
                            </h5>
                          </div>
                          <div className="uk-modal-footer uk-text-right">
                            <button
                              onClick={() => this.onClickLogout("close")}
                              className="uk-button uk-button-default uk-toggle uk-modal-close  "
                              type="button"
                              style={{ margin: "0 2px" }}
                            >
                              Cancel
                            </button>
                            <button
                              onClick={() => this.onClickLogout("logout")}
                              // onClick={() => this.props.userSignOut()}
                              className="uk-button uk-button-grey uk-dropdown-close uk-modal-close"
                              type="button"
                              style={{ margin: "0 2px" }}
                            >
                              Confirm{" "}
                            </button>
                          </div>
                        </div>
                      </div>
                      */}
                    </li>
                  </ul>
                </div>
              </li>
            </ul>
          </div>
          {/* <!-- search box --> */}
          <div id="modal-full" className="uk-modal-full uk-modal uk-animation-scale-down" data-uk-modal>
            <div className="uk-modal-dialog uk-flex uk-flex-center" data-uk-height-viewport>
              <button className="uk-modal-close-full" type="button" data-uk-close></button>
              <form className="uk-search uk-margin-xlarge-top uk-search-large uk-animation-slide-bottom-medium">
                <i className="fas fa-search uk-position-absolute uk-margin-top icon-xxlarge"></i>
                <input
                  className="uk-search-input uk-margin-large-left"
                  type="search"
                  placeholder="Search..."
                // autofocus
                />
              </form>
            </div>
          </div>
        </nav>
        {this.state.logoutModalVisible && <LogoutModal key="logoutModal" {...logoutModalProps} />}
      </div>
    );
  }
}

const mapStateToProps = ({ trainingInfo }) => {
  const { allTraineeTraining, myCartTraining } = trainingInfo;
  return { allTraineeTraining, myCartTraining };
};

export default withRouter(
  connect(
    mapStateToProps,
    {
      userSignOut,switchLanguage
    }
  )(TopNavbar)
);
