import React, { PureComponent } from "react";
import Auxiliary from "util/Auxiliary";

import { Row, Col, Card, Steps, message } from "antd";
// import TrainingForm  from './components/trainingForm';
import CircularProgress from "components/CircularProgress/index";
import StepsOne from "./components/stepsOne";
import StepsTwo from "./components/stepsTwo";
import StepsThree from "./components/stepsThree";
import StepsPreviewVideo from "./components/stepsPreviewVideo";
import StepsFour from "./components/stepsFour";

import ModelTraining from "../../traineeMain/model";
import { api, apiUrl } from "util/config";
const { importNewFileTraining, uploadMediaFileTraining } = api;

const { Step } = Steps;

const stepStyle = {
  marginBottom: 60,
  boxShadow: "0px -1px 0 0 #e8e8e8 inset"
};
const step2Style = { 
  boxShadow: "0px -1px 0 0 #e8e8e8 inset"
};

class Update extends PureComponent {
  state = {
    training: {},
    coverImageUrl: "",
    previewVideoUrl: "",
    visibility: false,
    fileUrl: "",
    steps: 0,
    current: 0,
    trainingId: "",
    loader: false,
    uploadStatus: true
  };
  componentWillMount() {
    if (this.props.match.params._id) {
      this.setState({
        trainingId: this.props.match.params._id,
        steps: 1,
        loader: true
      });
      var that = this;
      ModelTraining.fetchTrainingInfo(this.props.match.params._id).then(
        response => {
          console.log({ response });
          that.setState({
            loader: false,
            training: response.data,
            steps: 1
          });
        }
      );
    }
  }
  saveImageUrl = data => {
    this.setState({
      coverImageUrl: data
    });
  };
  savePreviewVideoUrl = data => {
    this.setState({
      previewVideoUrl: data
    });
  };
  saveFileUrl = data => {
    this.setState({
      fileUrl: data
    });
  };
  updateTrainingInfo = trainingInfo => {
    const { training, steps } = this.state;
    trainingInfo["steps"] = steps;
    trainingInfo["_id"] = training._id || trainingInfo._id;
    var that = this;
    that.setState({
      loader: true
    });
    ModelTraining.updateTraining(trainingInfo).then(response => {
      console.log({ response });
      if (response.data) {
        that.setState({
          training: response.data,
          steps: steps + 1
        });
      }
      that.setState({
        loader: false
      });
    });
  };
  saveTrainingInfo = () => {
    var that = this;
    const { training, steps } = that.state;
    training["steps"] = steps;
    ModelTraining.updateTraining(training).then(response => {
      console.log({ response });
      that.props.history.push(`/trainings/trainings`);
    });
  };

  saveVisibility = value => {
    this.setState({
      visibility: value
    });
  };

  onImgClose = () => {
    console.log("--------onImgClose--------");
    const { training, steps } = this.state;
    this.setState({
      coverImageUrl: training["coverImage"]
    });
    training["coverImage"] = "";
    this.setState({
      training: [],
      coverImageUrl: undefined,
      training: training
    });
    console.log("--------onImgClose--------", training);
  };
  onVideoClose = () => {
    console.log("-------onVideoClose---------");
    const { training, steps } = this.state;
    training["previewVideo"] = "";
    this.setState({ training });
    this.setState({
      previewVideoUrl: undefined
    });
  };

  back = () => {
    const { training, steps } = this.state;
    if (typeof training !== "undefined") {
      training["steps"] = steps - 1;
      this.setState({ training, steps: steps - 1 });
    }
  };
  next = () => {
    const { training, steps } = this.state;
    if (typeof training !== "undefined") {
      training["steps"] = steps + 1;
      this.setState({ training, steps: steps + 1 });
    }
  };

  beforeUpload = file => {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpgOrPng) {
      message.error("You can only upload JPG/PNG file!");
    }
    console.log({ file });
    const isLt2M = file.size / 1024 / 1024 < 0.5;
    console.log(isLt2M);
    if (!isLt2M) {
      message.error("Image must smaller than 300kb!");
    }
    return isJpgOrPng && isLt2M;
  };

  render() {
    const { training, loader, steps, coverImageUrl,uploadStatus } = this.state;
    var accessToken = localStorage.getItem("accessToken");

    const beforeUploadZip = file => {
      const isZip =
        file.type === "zip" ||
        file.type === "application/octet-stream" ||
        file.type === "application/zip" ||
        file.type === "application/x-zip-compressed" ||
        file.type === "application/x-zip"; 
      if (!isZip) {
        message.error(
          "You can only upload zip file!"
        );
        return isZip;
      } 
      return isZip;
    };
    const beforePreviewVideoUpload = file => { 
      const isVideo = 
        file.type === ".mov" ||
        file.type === ".m4v" ||
        file.type === ".mpg" ||
        file.type === ".mp4" ||
        file.type === ".avi" || 
        file.type === "video/mp4";
      if (!isVideo) {
        message.error("You can only upload video file!");
        return isVideo;
      } 
      return isVideo;
    };
    let that = this;
    const stepsOneProps = {
      fileUploadProps: {
        name: "file",
        multiple: false,
        action: apiUrl + importNewFileTraining + "?type=scorm",
        headers: {
          Authorization: `Bearer ${accessToken}`
        },
        accept: "application/gzip, application/zip, .gz",
        onChange(info) {
          const { status, response } = info.file;
          if (status !== "uploading") {
          }
          if (status === "done") {
            if (!response.error && response.status === 200) {
              response.data["steps"] = 1;
              that.setState({ training: response.data });
              that.setState({ steps: 1 });
            } else {
              message.error(response.userMessage);
            }
          } else if (status === "error") {
            message.error("Uploading failed. Please try again");
          }
        }
      },
      uploadFromPCProps: {
        name: "file",
        multiple: false,
        action:
        `${apiUrl}${"api/training/media/upload"}?steps=0&type=trainingVideo`,
          // apiUrl + uploadMediaFileTraining + "?type=trainingVideo&steps=0",
        headers: {
          Authorization: `Bearer ${accessToken}`
        },
        accept: ".mov,.m4v,.mpg,.mp4,.avi,video/*",
        beforeUpload: beforePreviewVideoUpload,
        onChange(info) {
          const { status, response } = info.file;
          if (status !== "uploading") {
          }
          if (status === "done") {
            if (!response.error && response.status === 200) {
              response.data["steps"] = 1;
              that.setState({ training: response.data, steps: 1 });
            } else {
              message.error(response.userMessage);
            }
          } else if (status === "error") {
            message.error("Uploading failed. Please try again");
          }
        }
      },
      uploadFromUrl: function(values) {
        values["steps"] = 1;
        that.setState({ training: values, steps: 1 });
      }
    };
    console.log({ coverImageUrl });
    console.log({ training });

    const stepsTwoProps = {
      updateTrainingInfo: this.updateTrainingInfo,
      training: training,
      state: this.state,
      onClickNext: this.next,
      onClickBack: this.back,
      fileUploadProps: {
        name: "file",
        multiple: false,
        action: apiUrl + "api/training/upload/scorm?_id="+training._id,
        headers: {
          Authorization: `Bearer ${accessToken}`
        },
        accept: "application/gzip, application/zip, .gz, .zip",

        beforeUpload: beforeUploadZip,
        onChange(info) {
          const { status, response } = info.file;
          if (status !== "uploading") {
          }
          if (status === "done") {
            if (!response.error && response.status === 200) {
              // response.data["steps"] = 1;   
              that.setState({  steps: 0},function(){ 
                training["resource"] = response.data.resource;
                training["imported"] = response.data.imported;
                training["scormVersion"] = response.data.scormVersion; 
                training["type"] = response.data.type; 
                training["showWeb"] = response.data.showWeb;
                that.setState({ training, steps: 1}); 
                }); 
            } else {
              message.error(response.userMessage);
            }
          } else if (status === "error") {
            message.error("Uploading failed. Please try again");
          }
        }
      },
    };
    const stepsThreeProps = {
      uploadProps: {
        name: "file",
        multiple: false,
        action: 
          `${apiUrl}${"api/training/media/upload"}?_id=${
            training._id
          }&steps=2&type=coverImage`, 
        headers: {
          Authorization: `Bearer ${accessToken}`
        },
        beforeUpload: this.beforeUpload,
        onChange(info) {
          const { status, response } = info.file;
          if (status !== "uploading") {
          }
          if (status === "done") {
            if (!response.error && response.status === 200) {
              response.data["steps"] = 3;
              that.setState({ training: response.data, steps: 3 });
            } else {
              message.error(response.userMessage);
            }
          } else if (status === "error") {
            message.error("Uploading failed. Please try again");
          }
        }
      },
      training: training,
      saveVisibility: this.saveVisibility,
      onImgClose: this.onImgClose,
      state: this.state,
      onClickNext: this.next,
      onClickBack: this.back
    };

    const stepsPreviewVideoProps = {
      uploadProps: {
        name: "file",
        multiple: false,
        action:
        `${apiUrl}${"api/training/media/upload"}?_id=${
          training._id
        }&steps=3&type=previewVideo`,  
        headers: {
          Authorization: `Bearer ${accessToken}`
        },
        accept: ".mov,.m4v,.mpg,.mp4,.avi,video/mp4",
        beforeUpload:beforePreviewVideoUpload,
        onChange(info) {
          const { status, response } = info.file;
          if (status !== "uploading") {
          }
          if (status === "done") {
            if (!response.error && response.status === 200) {
              response.data["steps"] = 4;
              that.setState({
                training: response.data,
                steps: 4
              });
            } else {
              message.error(response.userMessage);
              that.setState({ training: response.data, loader: false });
            }
          } else if (status === "error") {
            message.error("Uploading failed. Please try again");
            that.setState({ loader: false });
          } else {
            that.setState({ loader: false });
          }
        }
      },
      training: training,
      saveVisibility: this.saveVisibility,
      onPreviewVideoClose: this.onVideoClose,
      state: this.state,
      onClickNext: this.next,
      onClickBack: this.back
    };
    const stepsFourProps = {
      saveTrainingInfo: this.saveTrainingInfo,
      training: training,
      state: this.state,
      onClickNext: this.next,
      onClickBack: this.back
    };

    return (
      <Auxiliary>
        <Row>
          <Col xl={24} lg={24} md={24} sm={24} xs={24}>
            <h1>{this.state.trainingId ? "Update" : "Creator"} </h1>
            <div className="gx-campaign-space" />
          </Col>
        </Row>
        {loader ? (
          <div className="gx-loader-view">
            <CircularProgress />
          </div>
        ) : (
          <Card>
            <Steps
              type="navigation"
              size="small"
              current={steps}
              style={ steps == 1 ||steps == 4 ? step2Style :stepStyle}
            >
              <Step
                key="step_one"
                status={steps > 0 ? "finish" : "process"}
                title="Step 1"
              />
              <Step
                key="step_two"
                status={steps > 1 ? "finish" : steps === 1 ? "process" : "wait"}
                title="Step 2"
              />
              <Step
                key="step_three"
                status={steps > 2 ? "finish" : steps === 2 ? "process" : "wait"}
                title="Step 3"
              />
              <Step
                key="step_four"
                status={steps > 3 ? "finish" : steps === 3 ? "process" : "wait"}
                title="Step 4"
              />
              <Step
                key="step_five"
                status={steps > 4 ? "finish" : steps === 4 ? "process" : "wait"}
                title="Step 5"
              />
            </Steps>
            {steps == 0 && <StepsOne key="step_one_view" {...stepsOneProps} />}
            {steps == 1 && <StepsTwo key="step_two_view" {...stepsTwoProps} />}
            {steps == 2 && (
              <StepsThree key="step_image_view" {...stepsThreeProps} />
            )}
            {steps == 3 && (
              <StepsPreviewVideo
                key="step_video_view"
                {...stepsPreviewVideoProps}
              />
            )}
            {steps == 4 && (
              <StepsFour key="step_four_view" {...stepsFourProps} />
            )}
          </Card>
        )}
      </Auxiliary>
    );
  }
}

export default Update;
