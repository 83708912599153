import React from "react";
import { withRouter } from "react-router-dom";
import { message, Rate, Button } from "antd";
import moment from "moment";
import { Link, Redirect } from "react-router-dom";
import TopNavbar from "../components/TopNavbar";
import { imagePrefixUrl, apiUrl, stripeKey, imageMediaUrl } from "util/config";
import { Input } from "antd";
import styles from "./reviews.less";
import store from "store";
import CircularProgress from "components/CircularProgress/index";
import CartModel from "./../myCart/model";
import ModelTraining from "../../traineeMain/model";
import index from "../../components/navigation/Dropdown";
import ModelReview from "./model";
import Model from "../../trainings/$id/model";
import IntlMessages from "util/IntlMessages";


const { TextArea } = Input;
class CourseIntro extends React.PureComponent {
  state = {
    reviews: [],
    id: "",
    trainingType: "",
    ratingCount: 5,
    givenRating: 0,
    reviewsText: "",
    trainingId: "",
    trainingInfo: {},
    campaignInfo: {},
    loader: false,
    noDataFound: false,
    showReviewButton: true,
    enrollNowButton: true,
  };
  componentWillMount() {
    window.scrollTo(0, 0);
    const { match, location } = this.props;
    const { trainingInfoProps, campaignInfoProps } = location;
    const { params } = match;
    console.log("trainingInfoProps",trainingInfoProps)
    this.callUserEnrolled(params.trainingId);
    if (trainingInfoProps) {
      if (params.trainingType === "Assigned") {
        this.setState({
          campaignInfo: campaignInfoProps,
          trainingInfo: trainingInfoProps,
        });
        this.callReviewsApi(trainingInfoProps._id);
      } else {
        this.setState({ trainingInfo: trainingInfoProps });
        this.callReviewsApi(trainingInfoProps._id);
      }
    } else {
      this.callTrainingApi(params.trainingType, params.trainingId);
    }
    var randomRating = Math.floor(1 + Math.random() * 5);
    var randomRatingUser = Math.floor(10 + Math.random() * 150);
    var randomEnrollUser = Math.floor(150 + Math.random() * 400);
    if (randomRating < 3) {
      randomRating = 4;
    }
    this.setState({
      randomRating,
      randomRatingUser,
      randomEnrollUser,
      id: params.trainingId,
      trainingType: params.trainingType,
    });
  }
  componentDidUpdate() {
    const { match } = this.props;
    const { params } = match;
    console.log("this.state.id", this.state.id);
    console.log(" params.trainingId", params.trainingId);
    if (
      this.state.id !== params.trainingId
      //  ||
      // this.state.trainingType !== params.trainingType
    ) {
      window.scrollTo(0, 0);
      this.callTrainingApi(params.trainingType, params.trainingId);
      this.setState({
        id: params.trainingId,
      });
    }
  }
  callUserEnrolled(id){
     ModelTraining.fetchUserEnroledNumber(id).then((response) => {
        if (!response.error && response.data) {
          console.log(response.data,"response data")
          this.setState({
            totalUserEnrolled: response.data
          })
        }
      });
  }
  callTrainingApi(trainingType, id) {
    console.log({ trainingType });
    console.log({ id });
    if (trainingType === "Assigned") {
      var self = this;
      self.setState({
        loader: true,
        noDataFound: false,
        campaignInfo: {},
        trainingInfo: {},
        reviews: [],
      });

      ModelTraining.fetchTraineeTrainingInfo(id).then((response) => {
        if (!response.error && response.data) {
          if (response.data.trainingId) {
            self.setState({
              campaignInfo: response.data,
              trainingInfo: response.data.campaignId.trainingId,
              loader: false,
            });
            this.callReviewsApi(response.data.campaignId.trainingId._id);
          } else {
            self.setState({
              noDataFound: true,
              loader: false,
            });
          }
        } else {
          self.setState({
            noDataFound: true,
            loader: false,
          });
        }
      });
    } else {
      var self = this;
      self.setState({
        loader: true,
        noDataFound: false,
        campaignInfo: {},
        trainingInfo: {},
        reviews: [],
      });
      ModelTraining.fetchTrainingInfo(id).then((response) => {
        if (!response.error && response.data) {
          self.setState({
            trainingInfo: response.data,
            loader: false,
          });
          this.callReviewsApi(response.data._id);
        } else {
          self.setState({
            noDataFound: true,
            loader: false,
          });
        }
      });
    }
  }
  callReviewsApi(trainingId) {
    ModelReview.getReviews(trainingId).then((response) => {
      if (!response.error) {
        var { reviews } = this.state;
        reviews = response.data.concat(reviews);
        this.setState({
          reviews,
        });
      }
    });
  }

  addReviews = () => {
    if (this.state.givenRating == 0) {
      message.error("Please select rating.");
      return false;
    }
    if (this.state.reviewsText.trim().length == 0) {
      message.error("Please enter reviews.");
      return false;
    }
    this.setState({
      showReviewButton: false,
    });
    const { trainingInfo } = this.state;
    ModelReview.saveReviews(
      this.state.reviewsText,
      trainingInfo._id,
      store.get("user")._id,
      store.get("user").profilePhoto,
      this.state.givenRating
    ).then((response) => {
      if (!response.error) {
        var { reviews } = this.state;
        reviews = reviews.concat(response.data);
        this.setState({
          reviews,
          reviewsText: "",
          givenRating: 0,
          showReviewButton: true,
        });
      }
    });
  };

  giveRating = (val_) => {
    this.setState({
      givenRating: val_,
    });
  };
  setReviews = (e) => {
    this.setState({
      reviewsText: e.target.value,
    });
  };
  onClickStart = (url) => {
    console.log("------------------------------------");
    return this.props.history.push({
      pathname: url,
      trainingInfoProps: this.state.trainingInfo,
      campaignInfoProps: this.state.campaignInfo,
    });
  };
  onClickAddToCart = (training) => {
    var storeTraining = store.get("myCart");
    if (!storeTraining.some((info) => info.trainingId._id === training._id)) {
      CartModel.actionCart(training._id, "add").then((response) => {
        if (!response.error) {
          let trainerInfo = {};
          trainerInfo["trainingId"] = training;
          storeTraining.push(trainerInfo);
          store.set("myCart", storeTraining);

          var event = new CustomEvent("myCart", {
            detail: { storeTraining },
          });
          document.dispatchEvent(event);
          message.success(response.userMessage);
        } else {
          message.error(response.userMessage);
        }
      });
    } else {
      message.success("Added to Cart");
    }
  };

  createSessionCheckout = (training) => () => {
    fetch(`${apiUrl}api/lms/create-checkout-session`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        authorization: "Bearer " + localStorage.getItem("accessToken"),
      },
      body: JSON.stringify({
        trainingIds: [training._id],
      }),
    })
      .then((resp) => {
        return resp.json();
      })
      .then((resp) => {
        if (resp.error) {
          message.error(resp.userMessage, 5);
        } else {
          window
            .Stripe(stripeKey)
            .redirectToCheckout({
              sessionId: resp.data,
            })
            .then(function(result) {
              if (result.error) {
                message.error(result.error.message, 5);
              }
            });
        }
      });
  };

  enrollNow = (trainingId, item) => {
    //console.log({ trainingId });

    const organisationId = store.get("user").organisationId;
    const { match } = this.props;
    const { params } = match;
    this.setState({ enrollNowButton: false });
    var self = this;

    // this.state.trainingInfo.trainingRequest &&

    if (
      organisationId.isSelfAssign &&
      !(
        (this.state.trainingInfo.trainingRequest && this.state.trainingInfo.trainingRequest._id)  
      )
    ) {
      Model.assignTraining([store.get("user")], trainingId).then((response) => {
        if (response.error) {
          message.error(response.userMessage);
          self.setState({ enrollNowButton: true });
        } else {
          store.set("notificationCount", parseInt(store.get("notificationCount")) + 1);
          var event = new CustomEvent("notification", {
            detail: {
              notifications: response.data,
              status: 0,
            },
          });
          document.dispatchEvent(event);
          message.success(
            response.userMessage,
            (onclose = function() {
              // self.callTrainingApi(params.trainingType, params.trainingId);
              self.props.history.push(params.trainingType=="Practice"? "/trainee/course-dhashboard/" + item._id + '/'+ params.trainingType: "/trainee/course-dhashboard/" + item._id + '/'+ params.trainingType)
            })
          );
        }
      });
    } else {
      Model.trainingRequest(trainingId).then((response) => {
        if (response.error) {
          message.error(response.userMessage);
          self.setState({ enrollNowButton: true });
        } else {
          message.success(
            response.userMessage,
            (onclose = function() {
              self.callTrainingApi(params.trainingType, params.trainingId);
            })
          );
        }
      });
    }
  };
  render() {
    const { match } = this.props;
    const { params } = match;
    const { randomRating, randomRatingUser, randomEnrollUser } = this.state;
    const organisationId = store.get("user")?store.get("user").organisationId:{}
    
    if (this.state.noDataFound) {
      return (
        <div>
          <TopNavbar key="topNavbarKey" />
          <div
            style={{
              marginLeft: "10px",
              minHeight: "90vh",
            }}
            className="uk-child-width-1-2@s uk-child-width-1-3@m uk-grid-match uk-margin"
            data-uk-scrollspy="cls: uk-animation-slide-bottom-small; target: > div ; delay: 200"
            data-uk-grid
          >
            <div className="uk-child-width-1-4@m"></div>
            <div className="uk-child-width-2-4@m uk-text-center uk-margin">
              <div className="uk-text-center uk-margin">
                <div style={{ padding: "0 20%" }}>
                  <img src={require("assets/images/no_data_found.png")} />
                </div>
                <h3 className="uk-text-center " style={{ margin: "20px 0 0" }}>
                  Sorry!! No Data Found.
                </h3>
                <p className="uk-text-center uk-margin-remove">Try somethings else.</p>
              </div>
            </div>
            <div className="uk-child-width-1-4@m"></div>
          </div>
        </div>
      );
    }
    var ln = [];
    var rating = 0;
    if (this.state.trainingInfo && this.state.trainingInfo.rating) {
      rating = this.state.trainingInfo.rating ? this.state.trainingInfo.rating : 0;
    }

    rating = this.state.reviews.reduce(function(total, current) {
      return (total = total + current.rating);
    }, 0);

    rating = rating / this.state.reviews.length;
    rating = Math.round(rating);
    console.log(this.state, "-------------state");
    // if (this.state.reviews.length === 0) {
    //   rating = 5;
    // }
    for (var i = 0; i < 5; i++) {
      ln.push(i);
    }
    var services = store.get("user").organisationId.services;
    const isCscuServiseAvailable = services.includes("cscu");
    var instructorInfo = {};
    var instructorId = this.state.trainingInfo && this.state.trainingInfo.instructorId;
    instructorInfo["name"] = instructorId ? instructorId.name : "";
    instructorInfo["description"] = instructorId ? instructorId.description : "";
    instructorInfo["imageUrl"] = instructorId
      ? instructorId.imageUrl
        ? imageMediaUrl + instructorId.imageUrl
        : null
      : null;

    return (
      <div>
        <TopNavbar key="topNavbarKey" />
        {this.state.loader ? <CircularProgress></CircularProgress> : null}
        {!this.state.loader &&
          params.trainingType === "Assigned" &&
          this.state.trainingInfo &&
          this.state.campaignInfo &&
          this.state.campaignInfo.campaignId && (
            <div id="font-size-p" className="course-intro uk-text-center topic4">
              <h2 className={this.state.trainingInfo.language == "ar" ? "uk-light uk-text-uppercase uk-text-bold uk-text-white uk-margin-top rtl-text" : "uk-light uk-text-uppercase uk-text-bold uk-text-white uk-margin-top"} id="header-title">
                {this.state.trainingInfo.name}
              </h2>
              <p  className={this.state.trainingInfo.language == "ar" ? "uk-light uk-text-bold rtl-text":"uk-light uk-text-bold"} id="header-desc">
                {this.state.trainingInfo.shortDescription}
              </p>

              <p className="uk-light uk-text-bold uk-text-small" style={{ fontSize: "16px" }}>
                {/* {ln.map((item, index) => {
                  if (index < totalRating) {
                    return (
                      <i
                        key={"icon-rate" + item}
                        className="fas fa-star ic-icon-small ic-icon-rate"
                      ></i>
                    );
                  } else {
                  }
                })} */}
                {/* <Rate disabled value={rating ? rating : 0} /> */}
                {/* <Rate disabled value={randomRating ? randomRating : 0} /> */}
                {/* <span className="uk-margin-small-right" style={{ fontSize: "16px" }}> */}
                  {/* &nbsp;&nbsp; */}
                  {/* {rating ? rating + ".0" : 0} &nbsp;&nbsp; (
                  {this.state.reviews ? this.state.reviews.length : `0`}{" "}
                  ratings){" "} */}
                  {/* {randomRating ? randomRating + ".0" : 0} &nbsp;&nbsp; ({randomRatingUser} <IntlMessages id="trainee.ratings"/> ){" "} */}
                {/* </span> */}
                {/* <span className="uk-margin-small-right"> 4.0 (2282 ratings) </span>  */}
                <br />
                <i className="fas fa-user ic-icon-small uk-margin-small-right"></i>
                {/* {this.state.campaignInfo.userEnrolled
                  ? this.state.campaignInfo.userEnrolled
                  : `0`} */}
                {this.state.totalUserEnrolled || 0} <IntlMessages id={this.state.totalUserEnrolled && this.state.totalUserEnrolled == 1 ? "trainee.enrolled" : "trainee.enroll"}/> 
              </p>

              <Link
                className="uk-button uk-button-white"
                to={{
                  pathname:
                    this.state.campaignInfo.attempted && this.state.campaignInfo.completionStatus === "completed"
                      ? "/trainee/result/" + this.state.campaignInfo.campaignId._id
                      : "/trainee/course-dhashboard/" +
                        this.state.campaignInfo.campaignId._id +
                        "/" +
                        params.trainingType,
                  trainingInfoProps: this.state.trainingInfo,
                  campaignInfoProps: this.state.campaignInfo,
                }}
                //see result
                // data-uk-tooltip="title: Start this course now ; delay: 200 ; pos: top ;animation:	uk-animation-scale-up"
                data-uk-tooltip={`title: ${this.state.campaignInfo.attempted && this.state.campaignInfo.completionStatus === "completed"
                ? "See result"
                : "Start this course now"} ; delay: 200 ; pos: top ;animation:	uk-animation-scale-up`}
                style={{ margin: "0 2px" }}
              >
                {this.state.campaignInfo.attempted && this.state.campaignInfo.completionStatus === "completed"
                  ? <IntlMessages id="trainee.completed"/>
                  : <IntlMessages id="trainee.startNow"/>}
              </Link>

              <a
                className="uk-button uk-button-white"
                data-uk-toggle="target: #modal-preview"
                uk-tooltip=" title: Watch preview video ; delay: 200 ; pos: top ;animation:	uk-animation-scale-up"
                style={{ margin: "0 2px" }}
              >
                <IntlMessages id="trainee.preview"/>
              </a>

              <div id="modal-preview" data-uk-modal>
                <div className="uk-modal-dialog">
                  <button
                    className="uk-modal-close-default uk-margin-small-top  uk-margin-small-right uk-light"
                    type="button"
                    data-uk-close
                  ></button>
                  <div className="uk-modal-header topic4 none-border">
                    <b className="uk-text-white uk-text-medium"> <IntlMessages id="trainee.preview"/> </b>
                  </div>
                  <video loop playsinline controls data-uk-video="autoplay: inview">
                    <source
                      src={
                        this.state.trainingInfo.previewVideo
                          ? `${imagePrefixUrl}${this.state.trainingInfo.previewVideo}`
                          : "videos/course-intro.mp4"
                      }
                      type="video/mp4"
                    />
                  </video>
                  {/* --> */}
                  <div className="uk-modal-body" >
                    <h4 className={this.state.trainingInfo.language == "ar" ? "rtl-text" : ""} >{this.state.trainingInfo.name}</h4>
                    <p className={this.state.trainingInfo.language == "ar" ? "rtl-text" : ""} > {this.state.trainingInfo.shortDescription}</p>
                  </div>
                  <div className="uk-modal-footer uk-text-right">
                    <button
                      className="uk-button uk-button-default uk-modal-close"
                      type="button"
                      style={{ margin: "0 2px" }}
                    >
                     <IntlMessages id="logoutmodal.cancel"/> 
                    </button>
                    {/* <button
                      onClick={() => {
                        this.onClickStart(
                          this.state.campaignInfo.attempted && this.state.campaignInfo.completionStatus === "completed"
                            ? "/trainee/result/" + this.state.campaignInfo.campaignId._id
                            : "/trainee/course-dhashboard/" +
                                this.state.campaignInfo.campaignId._id +
                                "/" +
                                params.trainingType
                        );
                      }}
                      className="uk-button uk-button-grey uk-modal-close"
                      type="button"
                      style={{ margin: "0 2px" }}
                    >
                      {this.state.campaignInfo.attempted && this.state.campaignInfo.completionStatus === "completed"
                        ? "Training Completed"
                        : "Start Now"}
                    </button>
                  */}
                  </div>
                </div>
              </div>
            </div>
          )}
        {!this.state.loader && params.trainingType !== "Assigned" && this.state.trainingInfo && (
          <div id="font-size-p" className="course-intro uk-text-center topic4">
            <h2 className={this.state.trainingInfo.language == "ar" ? "uk-light uk-text-uppercase uk-text-bold uk-text-white uk-margin-top rtl-text":"uk-light uk-text-uppercase uk-text-bold uk-text-white uk-margin-top"} id="header-title">
              {this.state.trainingInfo.name}
            </h2>
            <p className={this.state.trainingInfo.language == "ar" ?"uk-light uk-text-bold rtl-text":"uk-light uk-text-bold"} id="header-desc">
              {this.state.trainingInfo.shortDescription}
            </p>

            <p className="uk-light uk-text-bold uk-text-small" style={{ fontSize: "16px" }}>
              {/* {ln.map(item => {
                  return (
                    <i className="fas fa-star ic-icon-small ic-icon-rate"></i>
                  );
                })} */}
              {/* <Rate disabled value={randomRating ? randomRating : 0} /> */}
              {/* <Rate disabled value={rating ? rating : 0} /> */}
              {/* <span className="uk-margin-small-right" style={{ fontSize: "16px" }}>
                &nbsp;&nbsp; */}
                {/* {rating ? rating + ".0" : 0} &nbsp;&nbsp; (
                  {this.state.reviews ? this.state.reviews.length : `0`}{" "}
                  ratings){" "} */}
                {/* {randomRating ? randomRating + ".0" : 0} &nbsp;&nbsp; ({randomRatingUser} ratings){" "}
              </span> */}
              {/* <span className="uk-margin-small-right"> 4.0 (2282 ratings) </span>  */}
              <br />
              <i className="fas fa-user ic-icon-small uk-margin-small-right"></i>
              {/* {this.state.trainingInfo.userEnrolled
                  ? this.state.trainingInfo.userEnrolled
                  : `0`} */}
                {this.state.totalUserEnrolled || 0} <IntlMessages id={this.state.totalUserEnrolled && this.state.totalUserEnrolled == 1 ? "trainee.enrolled" : "trainee.enroll"}/> 
            </p>
            {/* 
              {!(
                !store.get("user").trial &&
                isCscuServiseAvailable &&
                this.state.trainingInfo.trainingType === 3
              ) &&
                this.state.trainingInfo.trainingType !== 4 &&
                !this.state.trainingInfo.isPurchase &&
                !this.state.trainingInfo.isFree && (
                  <a
                    className="uk-button uk-button-white"
                    onClick={() => {
                      this.onClickAddToCart(this.state.trainingInfo);
                    }}
                  >
                    Add to Cart
                  </a>
                )}
              {!(
                !store.get("user").trial &&
                isCscuServiseAvailable &&
                this.state.trainingInfo.trainingType === 3
              ) &&
                this.state.trainingInfo.trainingType !== 4 &&
                !this.state.trainingInfo.isPurchase &&
                !this.state.trainingInfo.isFree && (
                  <a
                    className="uk-button uk-button-white"
                    onClick={this.createSessionCheckout(
                      this.state.trainingInfo
                    )}
                    style={{ margin: "0 2px" }}
                    // data-uk-tooltip="title: Start this course now ; delay: 200 ; pos: top ;animation:	uk-animation-scale-up" style={{margin: '0 2px'}}
                  >
                    {this.state.trainingInfo.isPurchase
                      ? ""
                      : "Buy now($" + this.state.trainingInfo.price + ")"}
                  </a>
                )} */}
            {!(
              this.state.trainingInfo.trainingRequest &&
              this.state.trainingInfo.trainingRequest._id) ||
              (this.state.trainingInfo.trainingRequest &&this.state.trainingInfo.trainingRequest.approved)||
              organisationId.isSelfAssign
              ? (
              <>
               {this.state.trainingInfo.enrolled && this.state.trainingInfo.enrolled.length > 0 ? (
                 <>
                 {(!store.get("user").trial && isCscuServiseAvailable && this.state.trainingInfo.trainingType === 3) ||
                this.state.trainingInfo.isPurchase ||
                this.state.trainingInfo.trainingType === 4 ||
                // this.state.trainingInfo.isFree ? (
                true ? (
                  <a
                    className="uk-button uk-button-white"
                    onClick={() => {
                      this.onClickStart(
                        "/trainee/course-dhashboard/" + this.state.trainingInfo._id + "/" + params.trainingType
                      );
                    }}
                    style={{ margin: "0 2px" }}
                    // data-uk-tooltip="title: Start this course now ; delay: 200 ; pos: top ;animation:	uk-animation-scale-up" style={{margin: '0 2px'}}
                  >
                    Start Now
                  </a>
                ) : null}
                 </>
               ):(
                  <a className="uk-button uk-button-white" style={{ margin: "0 2px" }}
                  // data-uk-tooltip="title:  Request is declined by Admin  ; delay: 300 ; pos: top ;animation:	uk-animation-slide-bottom-small"
                       
                  disabled={this.state.enrollNowButton ? false : true}
                  onClick={() => {
                    this.enrollNow(this.state.trainingInfo._id, this.state.trainingInfo);
                  }}  
                  >
                    Enroll Now
                  </a> 

               )}
                
              </>
            ) : (
              <>
                {this.state.trainingInfo.trainingRequest && this.state.trainingInfo.trainingRequest.rejected ? (
                  <a className="uk-button uk-button-white" style={{ margin: "0 2px" }}
                  data-uk-tooltip="title:  Request is declined by Admin  ; delay: 300 ; pos: top ;animation:	uk-animation-slide-bottom-small"
                       
                  disabled={this.state.enrollNowButton ? false : true}
                  onClick={() => {
                    this.enrollNow(this.state.trainingInfo._id, this.state.trainingInfo);
                  }}  
                  >
                    Enroll Again
                  </a> 
                ) : (
                  <a className="uk-button uk-button-white" style={{ margin: "0 2px" }} disabled>
                    Request Sent
                  </a>
                )}
              </>
            )}

            <a
              className="uk-button uk-button-white"
              data-uk-toggle="target: #modal-Preview"
              uk-tooltip=" title: Watch preview video ; delay: 200 ; pos: top ;animation:	uk-animation-scale-up"
              style={{ margin: "0 2px" }}
            >
                <IntlMessages id="trainee.preview"/>
            </a>

            <div id="modal-Preview" data-uk-modal>
              <div className="uk-modal-dialog">
                <button
                  className="uk-modal-close-default uk-margin-small-top  uk-margin-small-right uk-light"
                  type="button"
                  data-uk-close
                ></button>
                <div className="uk-modal-header topic4 none-border">
                  <b className="uk-text-white uk-text-medium"> Preview </b>
                </div>
                <video loop playsinline controls data-uk-video="autoplay: inview">
                  <source
                    src={
                      this.state.trainingInfo.previewVideo
                        ? `${imagePrefixUrl}${this.state.trainingInfo.previewVideo}`
                        : "videos/course-intro.mp4"
                    }
                    type="video/mp4"
                  />
                </video>
                {/* --> */}
                <div className="uk-modal-body">
                  <h4 className={this.state.trainingInfo.language == "ar" ? "rtl-text" : ""}>{this.state.trainingInfo.name}</h4>
                  <p className={this.state.trainingInfo.language == "ar" ? "rtl-text" : ""}> {this.state.trainingInfo.shortDescription}</p>
                </div>
                <div className="uk-modal-footer uk-text-right">
                  <button
                    className="uk-button uk-button-default uk-modal-close"
                    type="button"
                    style={{ margin: "0 2px" }}
                  >
                    <IntlMessages id="logoutmodal.cancel"/>
                  </button>

                  {/* {isCscuServiseAvailable ||
                  this.state.trainingInfo.isPurchase ||
                  this.state.trainingInfo.trainingType === 4 ||
                  this.state.trainingInfo.isFree ? (
                    <button
                      onClick={() => {
                        this.onClickStart(
                          "/trainee/course-dhashboard/" + this.state.trainingInfo._id + "/" + params.trainingType
                        );
                      }}
                      className="uk-button uk-button-grey uk-modal-close"
                      type="button"
                      style={{ margin: "0 2px" }}
                    >
                      {"Start Now"}
                    </button>
                  ) : (
                    <button
                      onClick={() => {
                        this.onClickAddToCart(this.state.trainingInfo);
                      }}
                      className="uk-button uk-button-grey uk-modal-close"
                      type="button"
                      style={{ margin: "0 2px" }}
                    >
                      {"Add to Cart"}
                    </button>
                  )} */}
                </div>
              </div>
            </div>
          </div>
        )}

        {/* {!this.state.loader && this.state.trainingInfo && (
          <SubShare {...this.state.trainingInfo} />
        )} */}
        {!this.state.loader && this.state.trainingInfo && (
          <ul
            className="uk-tab uk-flex-center  uk-margin-remove-top uk-background-default  uk-sticky"
            data-uk-sticky="animation: uk-animation-slide-top; bottom: #sticky-on-scroll-up ; media: @s;"
            data-uk-switcher="{connect:'#my-id'}"
          >
            <li aria-expanded="true" className="uk-active">
              <a href="#"> 
                     <IntlMessages id="trainee.courseIntro"/> 
              </a>
            </li>
            {/* <li>
              <a href="#"> Instructure </a>
            </li> */}
            <li>
              <a href="#"> 
                     <IntlMessages id="trainee.review"/> 
              </a>
            </li>
          </ul>
        )}

        {!this.state.loader && this.state.trainingInfo && (
          <ul
            id="my-id"
            className="uk-switcher"
            className="uk-switcher uk-margin uk-padding-small uk-container-small uk-margin-auto  uk-margin-top"
          >
            {/* <!-- tab 1 About the course --> */}
            <li className="uk-active uk-text-small ">
              <div
                className={this.state.trainingInfo.language == "ar" ? "rtl-text-right":""}
                id="font-size-p"
                dangerouslySetInnerHTML={{
                  __html: this.state.trainingInfo.description ? this.state.trainingInfo.description : "",
                }}
              />
            </li>

            {/* <!-- tab 2 About the instructor --> */}
            {/* <li>
              <h2 className="uk-heading-line uk-text-center">
                <span> Meet the Instructor </span>
              </h2>
              <div
                className="uk-grid-small  uk-margin-medium-top uk-padding-small"
                data-uk-grid
              >
                <div className="uk-width-1-4@m uk-first-column">
                  {instructorInfo.imageUrl && (
                    <img
                      alt="Image"
                      className="uk-width-2-3 uk-margin-small-top uk-margin-small-bottom uk-border uk-box-shadow-large  uk-animation-scale-up"
                      src={instructorInfo.imageUrl}
                    />
                  )}
                  <div className="uk-text-small uk-margin-small-top">
                   </div>
                </div>
                <div className="uk-width-3-4@m uk-padding-remove-left">
                   <h4 className="uk-margin-remove"> {instructorInfo.name} </h4>
                  <span className="uk-text-small">
                    {" "}
                    {instructorInfo.specialization}{" "}
                  </span>
                  <hr className="uk-margin-small" />

                  <span
                    dangerouslySetInnerHTML={{
                      __html: instructorInfo.description
                    }}
                  />
                </div>
              </div>
            </li> */}

            <li>
              <h2 className="uk-heading-line uk-text-center">
                <span><IntlMessages id="trainee.review"/>
                </span>
              </h2>
              {this.state.reviews.length > 0
                ? this.state.reviews.map((reviews) => {
                    return (
                      <div className="uk-grid-small uk-margin-medium-top" data-uk-grid>
                        <div className="uk-width-1-5 uk-first-column">
                          <img
                            style={{ width: "62px" }}
                            alt="Image"
                            className="uk-width-1-2 uk-margin-small-top uk-margin-small-bottom uk-border-circle uk-align-center  uk-box-shadow-large"
                            src={
                              reviews.userId && reviews.userId.profilePhoto && reviews.userId.profilePhoto != ""
                                ? `${imageMediaUrl}${reviews.userId.profilePhoto}`
                                : require("assets/images/avatures/avature-2.png")
                            }
                          />
                        </div>
                        <div className="uk-width-4-5 uk-padding-remove-left">
                          <div className="uk-float-right">
                            <Rate disabled value={reviews.rating ? reviews.rating : 0} />
                          </div>
                          {/* <h4 className="uk-margin-remove">
                              {" "}
                              {reviews.userId
                                ? reviews.userId.userName
                                : "NA"}{" "}
                            </h4> */}

                          <div className="uk-width-5-6">
                            <p className="uk-margin-remove-bottom uk-text-bold uk-text-small">
                              {reviews.userId ? reviews.userId.userName : "NA"}
                            </p>
                            <span className="uk-text-small" style={{color: "#5d5d5d",
    fontSize: "0.74rem"}}> 
                              {moment(reviews.createdAt)
                                .startOf("minutes")
                                .fromNow()}
                            </span>
                          </div>
                          <hr className="uk-margin-small" />
                          {/* <span className="uk-text-small">
                              {" "}
                              {moment(reviews.createdAt)
                                .startOf("minutes")
                                .fromNow()}
                            </span> */}
                          <p className="uk-margin-remove uk-text-small">{reviews.description}</p>
                          {/* <p className="uk-margin-remove-top uk-margin-small-bottom">
                              {reviews.description}
                            </p> */}
                        </div>
                      </div>
                    );
                  })
                : ""}
              <div className="uk-grid-small uk-margin-medium-top" data-uk-grid>
                <div className="uk-width-1-5 uk-first-column">
                  <img
                    style={{ width: "62px" }}
                    src={
                      store.get("user").profilePhoto && store.get("user").profilePhoto != ""
                        ? `${imageMediaUrl}${store.get("user").profilePhoto}`
                        : require("assets/images/avatures/avature-2.png")
                    }
                    alt="Image"
                    className="uk-width-1-2 uk-margin-small-top uk-margin-small-bottom uk-border-circle uk-align-center  uk-box-shadow-large"
                  />
                </div>
                <div className="uk-width-4-5 uk-padding-remove-left">
                  <div>
                    <div className="uk-float-left">
                      <p className="uk-margin-remove-bottom uk-text-bold uk-text-small">{store.get("user").userName}</p>
                    </div>
                    <div className="uk-float-right" style={{ marginBottom: "8px" }}>
                      <Rate
                        onChange={(value) => {
                          this.giveRating(value);
                        }}
                        value={this.state.givenRating}
                        // value={reviews.rating ? reviews.rating : 0}
                      />
                      {/* {Array.apply(null, {
                        length: this.state.ratingCount
                      }).map((e, i) => {
                        if (this.state.givenRating >= i + 1) {
                          return (
                            <i
                              onClick={() => this.giveRating(i + 1)}
                              className="fas fa-star ic-icon-small"
                            ></i>
                          );
                        } else {
                          return (
                            <i
                              onClick={() => this.giveRating(i + 1)}
                              className="far fa-star ic-icon-small"
                            ></i>
                          );
                        }
                      })} */}
                    </div>
                  </div>
                  <TextArea
                    className={styles.textAreaStyle}
                    rows={4}
                    placeholder="What do you think about this training?"
                    value={this.state.reviewsText}
                    onChange={this.setReviews}
                  />
                  {/* <input
                    style={{ margin: "10px 0 20px 0" }}
                    type="button"
                    value="Send"
                    disabled={this.state.showReviewButton ? false : true}
                    onClick={this.addReviews}
                  ></input> */}
                  <Button
                    style={{ margin: "10px 0 20px 0" }}
                    type="button"
                    value="Send"
                    disabled={this.state.showReviewButton ? false : true}
                    onClick={() => {
                      this.addReviews();
                    }}
                  >
                    <IntlMessages id="trainee.send"/>
                  </Button>
                </div>
              </div>
            </li>
          </ul>
        )}
      </div>
    );
  }
}

export default withRouter(CourseIntro);
