import React from "react";
import { withRouter } from "react-router-dom";
import { Form, Input, Row, Col, Button ,message} from "antd"; 
import socket from "util/socket";
import rsaWrapper from "util/rsa-wrapper";
import aesWrapper from "util/aes-wrapper";
import UserModel from "../../../profile/model";
const cookie = require("js-cookie");
// import UserModel from "../model"; 
class Settings extends React.PureComponent {
  state = {
    _id: 0,
    prevId: 0,
    loader: false,
    onClickButton: true
  };
  componentWillMount() { 
    message.destroy(); 
    this.socketConnect(); 
  }
  socketConnect() {
    socket.connect();
    var they = this;
    socket.on("CLIENT_PRIVATE_KEY", (privateKey) => {
      socket.emit("SECRET_KEY");
      socket.on("SECRET_KEY", (key) => {
        rsaWrapper
          .privateDecrypt(privateKey, key)
          .then((SECRET_KEY) => {
            they.AESKey = SECRET_KEY;
            they.setState({ connection: true });
            if (window.requestIdleCallback) {
              const fingerprint = btoa(navigator.userAgent); 
              cookie.set("fngprt", fingerprint, {
                secure: process.env.NODE_ENV !== "dev",
              }); 
            } else {
              const fingerprint = btoa(navigator.userAgent); 
              cookie.set("fngprt", fingerprint, {
                secure: process.env.NODE_ENV !== "dev",
              });  
            }
          })
          .catch((err) => {
            console.log("error occurred ", err.name);
            console.log("error occurred code ", err.code);
          });
      });
    });
  }
  handleSubmitRePassword = e => { 
    this.setState({ loader: true, onClickButton: false });
    e.preventDefault();
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        var objectValues = {};
        var AESKey = this.AESKey || localStorage.getItem("AESKey");
        aesWrapper
          .encryptMessage(AESKey, values.oldPassword)
          .then(encryptedPassword => {
            objectValues["oldPassword"] = encryptedPassword;
            aesWrapper
              .encryptMessage(AESKey, values.newPassword)
              .then(encryptedNewPassword => {
                objectValues["newPassword"] = encryptedNewPassword;
                this.setState({ loader: true });
                UserModel.userTraineePasswordReset(objectValues).then(
                  response => {
                    this.setState({ loader: false, onClickButton: true });
                  }
                );
              });
          });
      } else {
        this.setState({ loader: false, onClickButton: true });
      }
    });
  };
  handleConfirmBlur = e => {
    const { value } = e.target;
    this.setState({ confirmDirty: this.state.confirmDirty || !!value });
  };

  compareToFirstPassword = (rule, value, callback) => {
    const { form } = this.props;
    if (value && value !== form.getFieldValue("newPassword")) {
      callback("Password mismatch");
    } else {
      callback();
    }
  };

  validateToNextPassword = (rule, value, callback) => {
    const { form } = this.props;
    if (value && this.state.confirmDirty) {
      form.validateFields(["confirm"], { force: true });
    }
    var regularExpression = /^(?=.*\d)(?=.*[!@#$%^&*.-?_])(?=.*[a-z])(?=.*[0-9]).{4,}$/;
    if (form.getFieldValue("newPassword")) {
      if (!regularExpression.test(value)) {
        callback(
          "Password should contain at least 1 special character, number and character"
        );
      } else {
        callback();
      }
    } else {
      callback();
    }
  };

  render() {
    const { loader } = this.state;

    const { getFieldDecorator } = this.props.form;

    const formItemLayout = {
      style:{lineHeight:28},
      labelAlign: "left",
      labelCol: {
        xs: { span: 24 },
        sm: { span: 24 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 24 },
      },
    };
   

    return (
      <div
      className="uk-card-default uk-card-hover  uk-card-small Course-card uk-inline-clip uk-transition-toggle"
      tabindex="0"
    >
      <div className="reset-password-top text-center">
        <h3 className="panel-title text-center">
          {/* <i className="fa fa-custom-reset-password"></i> */}
                  <img src={require("assets/images/reset_password_icon.png")}></img>
          {"Reset Password"}
        </h3>
        <img src={require("assets/images/reset_password_image.png")}></img>
      </div>

      <div className="panel-body">
        <div id="custom-ant-input">
        <Form {...formItemLayout} onSubmit={this.handleSubmitRePassword} style={{margin:20}}>
                <Row>
                  <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                    <Form.Item label="Old Password">
                      {getFieldDecorator("oldPassword", {
                        rules: [
                          {
                            required: true,
                            pattern: /^[a-zA-Z0-9_\-,]/,
                            message: "Please input Old Password!"
                          }
                        ]
                      })(
                        <Input
                          type="password"
                          placeholder={`Old Password`}
                        />
                      )}
                    </Form.Item>
                    <Form.Item label="New Password">
                      {getFieldDecorator("newPassword", {
                        rules: [
                          {
                            required: true,
                            // pattern: /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[0-9]).{4,}$/,
                            message: "Please input new password!"
                          },
                          {
                            validator: this.validateToNextPassword
                          }
                        ]
                      })(
                        <Input
                          type="password"
                          onPressEnter={this.handleOk}
                          placeholder={`New Password`}
                        />
                      )}
                    </Form.Item>
                    <Form.Item label="Confirm Password">
                      {getFieldDecorator("confirmPassword", {
                        rules: [
                          {
                            required: true,
                            message: "Please input confirm password!"
                          },
                          {
                            validator: this.compareToFirstPassword
                          }
                        ]
                      })(
                        <Input
                          onBlur={this.handleConfirmBlur}
                          type="password"
                          onPressEnter={this.handleOk}
                          placeholder={`Confirm Password`}
                        />
                      )}
                    </Form.Item>
            <div className=" uk-text-center uk-margin" > 
                    <Form.Item style={{marginTop:"78px"}} >  <Button
                          type="info"
                          htmlType="submit"
                          disabled={
                            this.state.onClickButton ? false : true
                          }
                        >
                          Reset
                        </Button> 
                    </Form.Item>
                      </div>
                  </Col>
                </Row>
              </Form>
            
        </div>
      </div>
    </div>
  
    );
  }
}

export default Form.create()(withRouter(Settings));
// export default withRouter(MainView) ;
