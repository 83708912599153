import React, { PureComponent } from "react";
import Auxiliary from "util/Auxiliary";

import {
  Row,
  Col,
  Card,
  Steps,
  message,
  Upload,
  Icon,
  Button,
  Input
} from "antd";
// import TrainingForm  from './components/trainingForm';
import CircularProgress from "components/CircularProgress";
import ModelInstructor from "../model";
import { api, apiUrl, imageMediaUrl } from "util/config";
import styles from "./../../creator/components/trainingForm.less";
import LzEditor from "react-lz-editor";
// import "antd/dist/antd.less";
const { Dragger } = Upload;
const { Step } = Steps;

const stepStyle = {
  marginBottom: 60,
  boxShadow: "0px -1px 0 0 #e8e8e8 inset"
};

class Instructor extends PureComponent {
  state = {
    imageUrl: undefined,
    description: "Description",
    name: "",
    instructorId: "",
    loader: false
  };
  componentWillMount() {
    const { match } = this.props;
    console.log({ match });
    if (match.params._id) {
      this.fatchInstructor(match.params._id);
    } else {
      this.setState({ loader: false });
    }
  }
  componentDidUpdate() {
    const { match } = this.props;
    console.log({ match });
    if (match.params._id) {
      if (this.state.instructorId !== match.params._id) {
        this.fatchInstructor(match.params._id);
      }
    } else {
      this.setState({
        imageUrl: undefined,
        description: "Description",
        name: "",
        instructorId: "",
        loader: false
      });
    }
  }

  fatchInstructor = instructorId => {
    this.setState({ loader: true });
    ModelInstructor.fetchTrainingInstructor(instructorId).then(response => {
      if (!response.error) {
        var data =
          response.data && response.data.length > 0 ? response.data[0] : {};
        console.log("----------------------", data);
        this.setState({
          instructorId: data["_id"],
          imageUrl: data.imageUrl, // ? data.imageUrl : undefined,
          description: data.description,
          name: data.name,
          loader: false,
          totalRecords: response.totalRecords
        });
      } else {
        this.setState({ loader: false });
      }
    });
  };
  onImgClose = () => {
    console.log("onImgClose")
    this.setState({
      imageUrl: undefined
    });
  };

  beforeUpload = file => {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpgOrPng) {
      message.error("You can only upload JPG/PNG file!");
    }
    console.log({ file });
    const isLt2M = file.size / 1024 / 1024 < 0.5;
    console.log(isLt2M);
    if (!isLt2M) {
      message.error("Image must smaller than 300kb!");
    }
    return isJpgOrPng && isLt2M;
  };

  receiveHtml = content => {
    console.log(content);
    if (content !== "<p >Description</p>") {
      this.setState({ description: content });
    }
    // this.setState({content})
  };
  changeName = e => {
    this.setState({
      name: e.target.value
    });
  };
  saveChange = () => {
    var obj = {};
    obj["isUpdate"] = 1;
    obj["instructorId"] = this.state.instructorId;
    obj["name"] = this.state.name;
    obj["imageUrl"] = this.state.imageUrl;
    obj["description"] = this.state.description;
    ModelInstructor.updateTrainingInstructor(obj).then(response => {
      console.log({ response });
      if (!response.error) {
        this.props.history.push(`/trainings/instructor`);
      }
      // ;
    });
  };
  render() {
    const { description, loader, name, imageUrl } = this.state;
    var accessToken = localStorage.getItem("accessToken");
    var that = this;
    const uploadProps = {
      name: "file",
      multiple: false,
      action: apiUrl + "api/training/upload",
      headers: {
        Authorization: `Bearer ${accessToken}`
      },
      beforeUpload: this.beforeUpload,
      onChange(info) {
        const { status, response } = info.file;
        if (status !== "uploader") {
        }
        if (status === "done") {
          if (!response.error && response.status === 200) {
            that.setState({ imageUrl: response.data });
          } else {
            message.error(response.userMessage);
          }
        } else if (status === "error") {
          message.error("Uploader failed. Please try again");
        }
      }
    };

    console.log(this.state, "-------------");
    return (
      <Auxiliary>
        <Row>
          <Col xl={24} lg={24} md={24} sm={24} xs={24}>
            <h1>{"Create New Instructor"} </h1>
            <div className="gx-campaign-space" />
          </Col>
        </Row>
        {/* {loader ? (
          <div className="gx-loader-view">
            <CircularProgress />
          </div>
        ) : null} */}
        {!loader && (
          <Card>
            <Row className=" gx-p-5">
              <Col xl={8} lg={8} md={8} sm={24} xs={24}></Col>
              <Col xl={8} lg={8} md={8} sm={24} xs={24}>
                {imageUrl === undefined ? (
                  <Dragger {...uploadProps}>
                    <p className="ant-upload-drag-icon">
                      <Icon
                        type="picture"
                        style={{ height: 150, objectFit: "cover" }}
                      />
                    </p>
                    <p style={{ position: "relative", bottom: "28px" }}>
                      Click or drag image to this area to upload cover image
                    </p>
                  </Dragger>
                ) : (
                  <div>
                    <img
                      // className={styles.imagePreview}
                      src={`${imageMediaUrl}${imageUrl}`}
                      alt="preview"
                      style={{
                        height: 200,
                        objectFit: "cover",
                        border: "1px solid #e8e8e8"
                      }}
                    />

                <Button 
                  className="closeBtn"
                  shape="circle"
                  icon="close"
                  onClick={() => this.onImgClose()}
                />
                    {/* <Button
                      style={{ position: "absolute" }}
                      // className={styles.closeBtn}
                      shape="circle"
                      icon="close"
                      onClick={() => this.onImgClose()}
                    /> */}
                  </div>
                )}
              </Col>
              <Col xl={8} lg={8} md={8} sm={24} xs={24}></Col>
              <Col xl={24} lg={24} md={24} sm={24} xs={24} style={{padding: "16px"}}>
                <label style={{fontSize:16}}>Name</label>
              </Col>
              <Col xl={10} lg={10} md={10} sm={24} xs={24}>
                <Input type="text" value={name} onChange={this.changeName} />
              </Col>
              <Col xl={24} lg={24} md={24} sm={24} xs={24} style={{padding: "16px"}}>
                <label style={{fontSize:16}}>Description</label>
              </Col>
              <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                <LzEditor
                  active={true}
                  importContent={description}
                  video={false}
                  audio={false}
                  image={false}
                  urls={false}
                  color={false}
                  autoSave={false}
                  cbReceiver={this.receiveHtml}
                  // uploadProps={uploadProps}
                  lang="en"
                />
              </Col>
              <Col xl={24} lg={24} md={24} sm={24} xs={24}>
              <div style={{ float:"right", paddingTop: "16px"}}>
                <Button type="info" onClick={this.saveChange} style={{marginRight:0}}>
                  {this.props.match.params._id ? "Update" : "Submit"}
                </Button>
              </div>
              </Col>
            </Row>
          </Card>
        )}
      </Auxiliary>
    );
  }
}

export default Instructor;
