import React from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { message } from "antd";
import { Link } from "react-router-dom";
import InfiniteScroll from "react-infinite-scroll-component";
import IntlMessages from "util/IntlMessages";


import {
  hideMessage,
  showMessage,
  getTraineeAllTraining,
  getAllTraining,
  actionBookmarksTraining,
  showModal,
  hideModal,
  showLoader
} from "./../../appRedux/actions/Training";

import CircularProgress from "components/CircularProgress/index";

import TopNavbar from "./components/TopNavbar";
import CatagoryNavbar from "./components/CatagoryNavbar";
import CatagoryInfo from "./components/CatagoryInfo";
import TrainingTypeInfo from "./components/CatagoryInfo/trainingType";
import Description from "./courseDhashboard/components/Description";

import {
  description,
  trainerDescription,
  trainerInfo,
  arrCategoryTraining,
  arrCategoryAll,
  arrCategoryPremium,
  arrCategoryCSCU,
  arrGiedeaCategoryAll
} from "./data";
import bookmarks from "./bookmarks";

import store from "store";

class MainView extends React.PureComponent {
  state = {
    loadingState: false,
    _id: 0,
    clientHeight: 100,
    scrollHeight: 0,
    scrollTop: 0,
    page: 0,
    count: 0,
    changeType: false,
    changePage: false,
    totalRecords: 0,
    trainingInfo: {},
    allTraining: [],
    assignedTraining: [],
    trainingType: "",
    allTrainings: [],
    items: [],
    hasMore: true,
    services: ["training"],
    showExploreTab:false,
    isGiedea:false
  };

  componentWillMount() {
    window.scrollTo(0, 0);
    const { match } = this.props;
    const { params } = match;
    var user = store.get("user");
    const services = user.organisationId ? user.organisationId.services || [] : []
    const isGiedea = user.organisationId ? user.organisationId.isGiedea : false;
    const showExploreTab = user.organisationId ? user.organisationId.showExploreTab : false
    this.setState({ services: services, isGiedea: isGiedea, showExploreTab: showExploreTab });
    // console.log(user.organisationId.services); 
    if (params.trainingType) {
      //Assigned / Premium / Standard/ Practice/CSCU
      if (params.trainingType === "Assigned") {
        this.props.showLoader();
        this.props.getTraineeAllTraining({ page: this.state.page, showWeb: true });
        this.setState({ _id: 0 });
        this.setState({ trainingType: "Assigned" });
        this.setState({ allTrainings: [] });
      } else if (params.trainingType === "Premium") {
        this.props.showLoader();
        this.props.getAllTraining({ page: this.state.page, showWeb: true });
        this.setState({ _id: 1 });
        this.setState({ allTrainings: [] });
      } else if (params.trainingType === "Practice") {
        this.props.showLoader();
        this.props.getAllTraining({ trainingTypeId: 4, page: this.state.page, showWeb: true });
        this.setState({
          _id:
            services.includes("premium") || services.includes("standard")
              ? 2
              : 1
        });
        this.setState({ allTrainings: [] });
      } else if (params.trainingType === "CSCU") {
        this.props.showLoader();
        this.props.getAllTraining({ trainingTypeId: 3, page: this.state.page, showWeb: true });
        this.setState({
          _id:
            services.includes("premium") || services.includes("standard")
              ? 3
              : 2
        });
        this.setState({ allTrainings: [] });
      }
    } else {
      this.props.showLoader();
      this.props.getTraineeAllTraining({ page: this.state.page, showWeb: true });
      this.setState({ _id: 0 });
      this.setState({ trainingType: "Assigned" });
      this.setState({ allTrainings: [] });
    }
  }

  // componentDidUpdate(prevProps) {
  //   const { match } = this.props;
  //   const { params } = match;
  //   if (params.trainingType !== prevProps.match.params.trainingType) {
  //     this.setState({ page: 0 });
  //     this.callQuery(0);
  //     this.setState({ allTrainings: [] });
  //     this.setState({ changeType: true });
  //   }
  // }

  callQuery(page) {
    console.log({ page });
    const { match } = this.props;
    const { params } = match;

    const services = this.state.services;
    const showExploreTab = this.state.showExploreTab;
    console.log("this.props", this.props);
    if (showExploreTab) {

      if (params.trainingType === "Assigned") {
        this.props.showLoader();
        this.props.getTraineeAllTraining({ page: page, showWeb: true });
        this.setState({ _id: 0 });
        this.setState({ trainingType: "Assigned" });
      } else if (params.trainingType === "Premium") {
        this.props.showLoader();
        this.props.getAllTraining({ page: page, showWeb: true });
        this.setState({ _id: 1 });
        this.setState({ trainingType: "Premium" });
      } else if (params.trainingType === "Practice") {
        this.props.showLoader();
        this.props.getAllTraining({ trainingTypeId: 4, page: page, showWeb: true });
        this.setState({
          _id:
            services.includes("premium") || services.includes("standard") ? 2 : 1
        });
        this.setState({ trainingType: "Practice" });
      } else if (params.trainingType === "CSCU") {
        this.props.showLoader();
        this.props.getAllTraining({ trainingTypeId: 3, page: page, showWeb: true });
        this.setState({
          _id:
            services.includes("premium") || services.includes("standard") ? 3 : 2
        });
        this.setState({ trainingType: "CSCU" });
      }
    } else {
      this.props.showLoader();
      this.props.getTraineeAllTraining({ page: page, showWeb: true });
      this.setState({ _id: 0 });
      this.setState({ trainingType: "Assigned" });

    }
  }

  loadMore = nextPage => {
    console.log(nextPage);
    var self = this;
    this.setState({ page: nextPage }, function () {
      this.callQuery(nextPage);
    });
  };
  componentWillReceiveProps(nextProps) {
    // var {allTraining} = this.props
    this.setState({
      allTrainings: this.state.allTrainings.concat(nextProps.allTraining)
    });
  }
  render() {
    var self = this;
    const {
      match,
      pagination,
      bookmarkInfo,
      allTraineeTraining,
      allTraining,
      loader,
      successMessage,
      showMessage,
      alertMessage,
      totalTraining,
      totalUserCount,
      totalDiscussion
    } = this.props;
    const { params } = match;
    console.log(this.props, "propsssse")
    var self = this;
    const trainingType = params.trainingType;
    console.log({ trainingType });
    const services = this.state.services;
    const showExploreTab = this.state.showExploreTab;
    var arrCategory = arrCategoryAll;
    if (services) {

      if (
        services.includes("premium") ||
        services.includes("standard") ||
        services.includes("cscu")
      ) {
        arrCategory = arrCategoryAll;
      } else if (services.includes("premium") || services.includes("standard")) {
        arrCategory = arrCategoryPremium;
      } else if (services.includes("cscu")) {
        arrCategory = arrCategoryCSCU;
      } else {
        arrCategory = arrCategoryTraining;
      }
    } else {
      arrCategory = arrCategoryAll;
    }

    if (this.state.isGiedea) {
      arrCategory = arrGiedeaCategoryAll
    }

    const trainingTypeChange = type => {
      return this.props.history.push("/trainee/" + type);
    };
    const bookmarksActionForCategoryInfo = (action, index, info) => {
      info["index"] = index;
      this.props.actionBookmarksTraining({
        trainingId: info.campaignId.trainingId._id,
        action: action,
        trainingInfo: info
      });
    };
    const bookmarksActionForTypeInfo = (action, index, info) => {
      info["index"] = index;
      this.props.actionBookmarksTraining({
        trainingId: info._id,
        action: action,
        trainingInfo: info
      });
    };

    return (
      <div>
        <TopNavbar key="topNavbarKey" />
        <ul key="catagory-navbar" className="uk-switcher switcher-container">
          <li
            className={
              !trainingType || trainingType === "Assigned" ? "uk-active" : null
            }
          >     
            <CatagoryNavbar totalTraining={totalTraining} totalUserCount={totalUserCount} totalDiscussion={totalDiscussion} categoryInfo={arrCategory[0]} index={1} />
          </li>
          {showExploreTab &&
            <li className={trainingType === "premium" ? "uk-active" : null}>
              <CatagoryNavbar categoryInfo={arrCategory[1]} index={2} />
            </li>
          }
          {false && <li className={trainingType === "practice" ? "uk-active" : null}>
            <CatagoryNavbar categoryInfo={arrCategory[2]} index={3} />
          </li>}
          {false && <li className={trainingType === "cscu" ? "uk-active" : null}>
            <CatagoryNavbar categoryInfo={arrCategory[3]} index={4} />
          </li>}
        </ul>
        <button
          className="uk-button uk-hidden@m  mobile-catagory-button"
          type="button"
          data-uk-toggle="target: #tabs-moible; cls: tabs-moible"
        >
          {" "}
          Open catagory{" "}
        </button>
        <ul
          key="mob"
          className="uk-subnav uk-subnav-pill tabs-moible-hidden"
          data-uk-switcher="connect: .switcher-container"
          data-uk-tab="{connect:'#my-id2'}"
          data-uk-toggle="target: #tabs-moible; cls: tabs-moible"
          id="tabs-moible"
        >
          {/* {services.includes("training") ? ( */}
          <li
            onClick={() => {
              trainingTypeChange("");
            }}
            className={
              !trainingType || trainingType === "Assigned"
                ? "uk-active"
                : null
            }
          >
            <Link
              to={{
                pathname: "/trainee/Assigned"
              }}
            >
            <IntlMessages
                id="mainTraining.heading"/>
              </Link>
          </li>
          {/* ) : null} */}

          {showExploreTab ? (
            // {services.includes("premium") || services.includes("standard") ? (
            <li
              onClick={() => {
                trainingTypeChange("premium");
              }}
              className={trainingType === "premium" ? "uk-active" : null}
            >
              <Link
                to={{
                  pathname: "/trainee/premium"
                }}
              >
                {" "}
              <IntlMessages
                id="mainTraining.explore"/>
              {" "}
              </Link>
            </li>
          ) : null}

          {false && <li
            onClick={() => {
              trainingTypeChange("practice");
            }}
            className={trainingType === "practice" ? "uk-active" : null}
          >
            <Link
              to={{
                pathname: "/trainee/practice"
              }}
            >
              Practice Quizes
            </Link>
          </li>}

          {/* {(services.includes("cscu") || store.get("user").trial) && false ? ( */}
          {false ? (
            <li
              onClick={() => {
                trainingTypeChange("cscu");
              }}
              className={trainingType === "cscu" ? "uk-active" : null}
            >
              <Link
                to={{
                  pathname: "/trainee/cscu"
                }}
              >
                {" "}
                CSCU Training{" "}
              </Link>
            </li>
          ) : null}
        </ul>

        <ul
          style={{ listStyleType: "none", marginTop: "43px", padding: "0px" }}
          className=" switcher-container uk-margin"
        >
          <li>
            {trainingType ? (
              <TrainingTypeInfo
                bookmarksAction={bookmarksActionForTypeInfo}
                trainingType={trainingType}
              />
            ) : (
                <CatagoryInfo bookmarksAction={bookmarksActionForCategoryInfo} />
              )}
          </li>
        </ul>
      </div>
    );
  }
}
const mapStateToProps = ({ auth, trainingInfo }) => {
  const { authUser, accessToken } = auth;
  const {
    loader,
    alertMessage,
    successMessage,
    showMessage,
    allTraining,
    allTraineeTraining,
    bookmarkInfo,
    pagination,
    totalTraining,
    totalUserCount,
    totalDiscussion
  } = trainingInfo;
  return {
    loader,
    alertMessage,
    successMessage,
    showMessage,
    authUser,
    accessToken,
    allTraineeTraining,
    allTraining,
    bookmarkInfo,
    pagination,
    totalTraining,
    totalUserCount,
    totalDiscussion
  };
};

export default withRouter(
  connect(mapStateToProps, {
    hideMessage,
    showMessage,
    getTraineeAllTraining,
    getAllTraining,
    actionBookmarksTraining,
    showModal,
    hideModal,
    showLoader
  })(MainView)
);
// export default withRouter(MainView) ;
