import React from "react";
import { Button, Checkbox, Form, Input, message, Row, Col } from "antd";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
// import { fingerprintjs2 } from 'fingerprintjs2'
import { hideMessage, showAuthLoader, userForgotPassword, clearUserData, userSignOutAuth } from "../../appRedux/actions/Auth";
import IntlMessages from "util/IntlMessages";
import CircularProgress from "components/CircularProgress";

import "./../../assets/css/uikit.css";
import "./../../assets/css/main.css";
import "./../../assets/css/fontawesome.css";

import { IconFont } from "util/Utils";
import { getSubdomain } from "../../util/Utils";
const FormItem = Form.Item;

class SignIn extends React.Component {
  state = {
    userData: null,
    connection: false,
  };
  handleSubmit = (e) => {
    message.destroy();
    this.props.hideMessage();
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.props.showAuthLoader();
        this.props.userForgotPassword(values);
      }
    });
  };

  componentWillMount() {
    window.scrollTo(0, 0);
    message.destroy();
    this.props.hideMessage();
    this.props.clearUserData();
    this.props.userSignOutAuth()
  }

  messageDestroy = (e) => {
    message.destroy();
    this.props.hideMessage();
  };
  render() {
    const { getFieldDecorator } = this.props.form;
    const { showMessage, loader, userMessage, alertMessage, successMessage } = this.props;
    console.log("------", this.props);
    return (
     
       <div
        style={{
          backgroundColor: "#f9f9f9",
          // backgroundImage: "linear-gradient(180deg,#aecee1,#acb0b8)"
          backgroundImage: "linear-gradient(180deg,#adadad,#101820)",
          // backgroundImage: "url(" + require("assets/images/backgrounds/login_bg_image.png") + ")",
        }}
      >
          {showMessage && message.destroy()}
        {showMessage && alertMessage != "" ? message.error(alertMessage.toString()) : null}
        {showMessage && successMessage != "" ? message.success(successMessage.toString()) : null}
      
        <div className="uk-flex uk-flex-middle" style={{ marginBottom: "-50px" }}>
        {/* <IconFont 
            type="icon-logo_web"
            style={{
              fontSize: "120px",
              marginTop: "0px",
              marginLeft: "45px",
            }}
          ></IconFont> */}
        <i className={getSubdomain() !== "geidea" ? ("fa fa-logo-black-icon " + getSubdomain()):"fa fa-logo-black-icon geidea"} style={{
              // fontSize: "120px",
              marginTop: "20px",
              marginLeft: "45px",
            }}></i>
        </div>
        <div data-uk-height-viewport="offset-top: true; offset-bottom: true" className="uk-flex uk-flex-middle">
          <div className="uk-width-2-3@m uk-width-1-2@s uk-margin-auto  border-radius-6 ">
          <div
              className="uk-child-width-1-2@m uk-background-grey uk-grid-collapse"
              data-uk-grid
              style={{ borderRadius: 10,margin:"0 40px" }}
            >
               <div  > 
                <img
                  className="welcome-image-login"
                  src={require("assets/images/welcome_image_1.png")} 
                /> 
              </div>

              <div id="login_container" style={{ borderRadius: 10 }}>
                <div
                  className="uk-card-default uk-padding uk-card-small custom-login-view"
                 
                >
                  {loader ? (
                    <div
                      style={{
                        position: "absolute",
                        left: "47.7%",
                        top: "-5%",
                        zIndex: "999",
                      }}
                    >
                      <CircularProgress />
                    </div>
                  ) : null}
                  {/* <!-- Login tab tab --> */}
                  <div id="login" className="tabcontent tab-default-open animation: uk-animation-slide-right-medium custom-login-view-2">
                    <div className="gx-app-login-content" style={{ width: "100%" }}>
                    <h2
                          className="uk-text-bold"
                          style={{ textAlign: "end", marginBottom: "40px", color: "#000", marginRight: "10px" }}
                        >
                        {" "}
                        <IntlMessages id="app.userAuth.forgotPassword" />
                      </h2>
                      {/* <p className="uk-text-muted uk-margin-remove-top uk-margin-small-bottom"> Fill blank to log your account</p>
                       */}
                      <Form className="gx-signin-form gx-form-row0">
                        {/* <div className="uk-form-label"> Email address </div> */}
                        <FormItem>
                          {getFieldDecorator("email", {
                            initialValue: "",
                            rules: [
                              {
                                required: true,
                                message: "Email address is missing.",
                              },
                              {
                                type: "email",
                                message: "Please enter valid email address.",
                              },
                            ],
                          })(
                            <Input
                              className="uk-input"
                              placeholder="Email"
                              onChange={this.messageDestroy}
                            />
                          )}
                        </FormItem>
                        {/* <p style={{color:'green'}}>{userMessage}</p> */}
                        {/* <p style={{ color: "green" }}>{successMessage}</p> */}

                        <FormItem className="btn-login">
                          <Button
                            id="next_btn"
                            // type="primary"
                            className="gx-mb-0 gx-next-btn uk-button-success"
                            htmlType="button"
                            onClick={this.handleSubmit}
                          >
                            <IntlMessages id="app.userAuth.resetPassword" />
                          </Button>
                        </FormItem>
                        <FormItem>
                          <span className="gx-signup-form-forgot gx-link login-link-color">
                            <Link to="/signin" style={{color:"#000 !important"}}>
                              <IntlMessages id="app.userAuth.backToLogin" />
                            </Link>
                          </span>
                        </FormItem>
                      </Form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div   style={{ textAlign: "center", padding: 20 }}>
          Copyright © All rights reserved |{" "}
          <a href="https://www.eccouncil.org/" target="_blank">
            EC-Council
          </a>
        </div>
      </div>
    );
  }
}

const WrappedNormalLoginForm = Form.create()(SignIn);

const mapStateToProps = ({ auth }) => {
  const { loader, alertMessage, showMessage, successMessage, userMessage } = auth;
  return { loader, alertMessage, showMessage, successMessage, userMessage };
};

export default connect(
  mapStateToProps,
  {
    hideMessage,
    showAuthLoader,
    userForgotPassword,
    clearUserData,
    userSignOutAuth
  }
)(WrappedNormalLoginForm);
