import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import Auxiliary from "util/Auxiliary";
import Widget from "components/Widget";
import CircularProgress from "components/CircularProgress";
import { Row, Col, Card, List, Progress, Divider } from "antd";
import Model from "./model";
import TopNavbar from "../components/TopNavbar";
// import styles from "./index.less";
import store from "store";
import { api, apiUrl } from "util/config";
var moment = require("moment");

class ResultView extends PureComponent {
  state = {
    data: {},
    loading: true,
  };
  componentWillMount() {
    const { match } = this.props;
    const trainingId = match.params.trainingId;
    Model.getTrainingDetails({ trainingId: trainingId }).then((response) => {
      this.setState({ data: response.data, loading: false, trainingId });
    });
  }
  componentDidMount(){
    const user = store.get("user")
    this.setState({user})
  }
  formateDateTime(date) {
    return moment(date).format("DD MMM YYYY,  hh:mm A");
  }
  calculatePercent = (max, score) => {
    return ((score / max) * 100).toFixed(0);
  };

  shareCertificate = (type) => {
    // console.log(e.target.getAttribute("data"));
    // const type = e.target.getAttribute("data");
    var self = this;
    console.log("download");
    const { dispatch, result, match } = this.props;
    // var { data } = result;
    var { loading, data ,user} = this.state;
    const training_id = match.params.trainingId; 
    var info = { 
      traineeId: data.trainee,
      name: user.userName,
      email: user.userEmail,}
    Model.shareTrainings(training_id,info).then((response) => {
      // this.setState({ data: response.data, loading: false, trainingId });
      
      self.share(response.data, data.trainee, training_id, type);
    });
    // dispatch({
    //   type: "result/share",
    //   payload: {
    //     campaignId: training_id,
    //     traineeId: data.trainee,
    //     name: user.userName,
    //     email: user.userEmail,
    //     callback: function(url) {
    //       self.share(url, data.trainee, training_id, type);
    //     },
    //   },
    // });
  };
  share = (url, traineeId, campaignId, type) => {
    // console.log({url})
    // url = `${process.env.API_URL}/certificate/${traineeId}/${campaignId}`;
    // url =
    //   'https://staging-portal.ohphish.com/assets/images/login_icon_image.png'

    if (type == "linkedIn") {
      var link = "http://www.linkedin.com/shareArticle?mini=true&url=" + encodeURIComponent(url);

      window.open(link, "", "left=0,top=0,width=650,height=420,personalbar=0,toolbar=0,scrollbars=0,resizable=0");
    } else {
      var link = "https://www.facebook.com/sharer/sharer.php?u=" + encodeURIComponent(url);
      window.open(link, "", "left=0,top=0,width=650,height=420,personalbar=0,toolbar=0,scrollbars=0,resizable=0");
    }
  };
  downloadCertificate = (e) => {
    const { dispatch, result, match } = this.props;
    // var { data } = result;
    var { loading, data ,user} = this.state;
    const training_id = match.params.trainingId;
    // $(e.target).addClass("loading");
    var request = new XMLHttpRequest();
    request.open(
      "GET",

      apiUrl+"api/trainings/" + training_id + "/download?traineeId=" + data.trainee,
      true
    );
    request.responseType = "blob";
    request.setRequestHeader("Authorization", "Bearer " + store.get("accessToken"));
    // request.setRequestHeader("fngprt", m.cookie.get("fngprt"));
    request.onload = function(e) {
      // $(e.target).removeClass("loading");
      if (this.status === 200) {
        var file = window.URL.createObjectURL(this.response);
        // dispatch({
        //   type: "result/updateState",
        //   payload: { file: file },
        // });
        var a = document.createElement("a");
        a.href = file;
        a.download = this.response.name || (user.userName || user.email) + ".pdf";
        document.body.appendChild(a);
        a.click();
        window.onfocus = function() {
          // document.body.removeChild(a);
        };
      }
    };

    request.send();
  };

  render() {
    var { loading, data } = this.state;
    var colSize = 12;
    var showScore =
      data.campaignId && data.campaignId.trainingId ? data.campaignId.trainingId.showScore || false : false;
    if (!showScore) {
      colSize = 24;
    }
    return (
      <div>
        <TopNavbar key="topNavbarKey" />
        <div className="uk-container uk-margin uk-align-center"></div>
        {loading ? (
          <CircularProgress></CircularProgress>
        ) : (
          <div className="uk-container uk-margin  uk-padding" style={{ margin: "20px" }}>
            <div
              className="uk-card uk-margin "
              style={{
                backgroundColor: "#b2cfbe",
                borderTopLeftRadius: "50px",
                borderTopRightRadius: "50px",
              }}
            >
              <div className="uk-padding">
                <h3 className="uk-margin" style={{ textAlign: "center" }}>
                  {data.campaignId.trainingId.name}
                </h3>
                <h5
                  className="uk-margin-remove"
                  style={{
                    textAlign: "center",
                    letterSpacing: "1px",
                    fontSize: "1.2rem",
                  }}
                >
                  Assigned On: {this.formateDateTime(data.assignedAt)}
                </h5>
                <h5
                  className="uk-margin-small-top"
                  style={{
                    textAlign: "center",
                    letterSpacing: "1px",
                    fontSize: "1.2rem",
                  }}
                >
                  Completed On: {data.submittedAt ? this.formateDateTime(data.submittedAt) : "Not completed yet"}
                </h5>
              </div>
              <div
                style={{
                  backgroundColor: "#ffffff",
                  borderTopLeftRadius: "50%",
                  borderTopRightRadius: "50%",
                  paddingTop: "40%",
                }}
              >
                <div style={{ marginTop: "-30%" }}>
                  <Row>
                    <Col
                      lg={8}
                      md={8}
                      sm={24}
                      xs={24}
                      style={{
                        textAlign: "center",
                      }}
                    >
                      <Progress
                        style={{ fontSize: "20px" }}
                        type="circle"
                        status={data.attempted ? "" : "exception"}
                        percent={data.attempted ? 100 : 0}
                        strokeWidth={17}
                        width={150}
                        strokeColor="#5bd775"
                      />
                      <div style={{ paddingTop: 20, fontSize: 24 }}>Training Completed</div>
                    </Col>
                    <Col lg={8} md={8} sm={24} xs={24} style={{ textAlign: "center" }}>
                      <Progress
                        style={{ fontSize: "20px" }}
                        type="circle"
                        status={data.successStatus == "passed" ? "" : "exception"}
                        percent={this.calculatePercent(data.scoreMax, data.scoreRaw)}
                        strokeWidth={17}
                        width={150}
                        strokeColor="#5f76e8"
                      />
                      <div style={{ paddingTop: 20, fontSize: 24 }}>Result</div>
                    </Col>
                    <Col lg={8} md={8} sm={24} xs={24} style={{ textAlign: "center" }}>
                      <div>
                        <img
                          src={require("assets/images/score_icon.png")}
                          style={{ width: "150px", padding: "10px" }}
                        />
                      </div>
                      <div style={{ paddingTop: 20, fontSize: 24 }}>
                        Score :<strong style={{ fontSize: "2rem" }}> {data.scoreRaw}</strong>
                      </div>
                    </Col>
                  </Row>

                  <div style={{ textAlign: "center", padding: "25px" }}>
                    <ul id="stats" style={{display:"inline-flex"}}>
                      {/* <li>
                        <img
                          style={{ height: 80 }}
                          src={require("assets/images/icon/certificate_image.png")}
                          style={{ paddingRight: "25px", height: "84px" }}
                        />
                      </li> */}
                      {data.successStatus == "passed" && (
                        <li>
                          <img
                            onClick={this.downloadCertificate}
                            style={{
                              height: 40,
                              width:40,
                              marginRight: 10,
                              cursor: "pointer",
                            }}
                            src={require("assets/images/icon/download_icon.png")}
                          />
                        </li>
                      )}

                      {data.successStatus == "passed" && (
                        <li>
                          <img
                            data="facebook"
                            onClick={()=>this.shareCertificate("facebook")}
                            style={{
                              height: 40,
                              width:40,
                              marginRight: 10,
                              cursor: "pointer",
                            }}
                            src={require("assets/images/icon/facebook_icon.png")}
                          />
                        </li>
                      )}
                      {data.successStatus == "passed" && (
                        <li>
                          <img
                            data="linkedIn"
                            onClick={()=>this.shareCertificate("linkedIn")}
                            style={{ height: 40, 
                              width:40,cursor: "pointer" ,
                              marginRight: 10,}}
                            src={require("assets/images/icon/linkedin_icon.png")}
                          />
                        </li>
                      )}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}
export default ResultView;
