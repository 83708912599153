import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import axios from "axios";
import RSA from "react-simple-auth";
import store from "store";
import {
  auth,
  facebookAuthProvider,
  githubAuthProvider,
  googleAuthProvider,
  twitterAuthProvider
} from "../../firebase/firebase";
import {
  SIGNIN_FACEBOOK_USER,
  SIGNIN_GITHUB_USER,
  SIGNIN_GOOGLE_USER,
  SIGNIN_TWITTER_USER,
  SIGNIN_USER,
  SIGNOUT_USER,
  SIGNOUT_AUTH_USER,
  SIGNUP_USER,
  FIND_USER,
  SIGNIN_MICROSOFT_USER,
  ON_PING,
  ON_PING_SUCCESS,
  ON_PING_TRAINEE,
  FORGOT_PASSWORD_USER,
  RESET_PASSWORD_USER,
  USER_REDIRECT
} from "./../../constants/ActionTypes";
import {
  showAuthMessage,
  hideMessage,
  hideAuthLoader,
  userSignIn,
  userSignInSuccess,
  userSignOutSuccess,
  userSignOutAuthSuccess,
  userSignOutAuth,
  userSignOut,
  userSignUpSuccess,
  userForgotPasswordSuccess,
  userResetPasswordSuccess,
  findUserSuccess,
  onPingSuccess,
  userForgotPassword,
  clearUserData
} from "../../appRedux/actions/Auth";
import {
  userFacebookSignInSuccess,
  userGithubSignInSuccess,
  userGoogleSignInSuccess,
  userTwitterSignInSuccess,
  userMicrosoftSignInSuccess
} from "../actions/Auth";
import { apiPrefix, api, apiUrl } from "./../../util/config";
import { user } from "../../routes/socialApps/Wall/data";
import { Redirect } from "react-router";
import { async } from "q"; 
const cookie = require("js-cookie");
var userAuthToken =""
const {
  queryAuthMethod,
  loginUser,
  authLoginUser,
  queryUserInfo,
  queryUserInfoElearning,
  queryResetPassword,
  queryForgotPassword
} = api;


const findUserWithEmailRequest = async email =>
  await axios
    .post(apiUrl + queryAuthMethod, {
      email: email
    })
    .then(response => response)
    .catch(error => error);

const userTraineeForgotPasswordRequest = async email =>
  await axios
    .post(apiUrl + "api/trainee/password/forgot", {
      email: email
    })
    .then(response => response)
    .catch(error => error);

const userForgotPasswordRequest = async email =>
  await axios
    .post(apiUrl + queryForgotPassword, {
      email: email
    })
    .then(response => response)
    .catch(error => error);

const userTraineeResetPasswordRequest = async payload =>
  await axios
    .post(apiUrl + "api/password/reset/v1", payload)
    .then(response => response)
    .catch(error => error);

const userResetPasswordRequest = async payload =>
  await axios
    .post(apiUrl + queryResetPassword, payload)
    .then(response => response)
    .catch(error => error);

const createUserWithEmailPasswordRequest = async (email, password) =>
  await auth
    .createUserWithEmailAndPassword(email, password)
    .then(authUser => authUser)
    .catch(error => error);

const signInUserWithAuthTokenRequest = async payload =>
  await axios
    .post(apiUrl + authLoginUser, payload)
    .then(response => response)
    .catch(error => error);

const signInUserWithEmailPasswordRequest = async payload =>
  await axios
    .post(apiUrl + loginUser, {
      ...payload
    })
    .then(response => response)
    .catch(error => error);

const onPingRequest = async () =>
  await axios
    .get(apiUrl + queryUserInfo, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("accessToken")
        // fngprt: cookie.get("fngprt")
      }
    })
    .then(response => response)
    .catch(error => error);

const onElearningPingRequest = async () =>
  await axios
    .get(apiUrl + queryUserInfoElearning, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("accessToken")  
      }
    })
    .then(response => response)
    .catch(error => error);
const onUserRedirectRequest = async token =>
  await axios
    .post(
      apiUrl + "api/checkToken",
      {
        token: token
      },
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json"
        }
      }
    )
    .then(response => response)
    .catch(error => error);

const signOutRequest = async () =>
  await auth
    .signOut()
    .then(authUser => authUser)
    .catch(error => error);

const signInUserWithMicrosoftRequest = async microsoftProvider =>
  await RSA.acquireTokenAsync(microsoftProvider)
    .then(session => session)
    .catch(error => error);

const signInUserWithGoogleRequest = async () =>
  await auth
    .signInWithPopup(googleAuthProvider)
    .then(authUser => authUser)
    .catch(error => error);

const signInUserWithFacebookRequest = async () =>
  await auth
    .signInWithPopup(facebookAuthProvider)
    .then(authUser => authUser)
    .catch(error => error);

const signInUserWithGithubRequest = async () =>
  await auth
    .signInWithPopup(githubAuthProvider)
    .then(authUser => authUser)
    .catch(error => error);

const signInUserWithTwitterRequest = async () =>
  await auth
    .signInWithPopup(twitterAuthProvider)
    .then(authUser => authUser)
    .catch(error => error);
//resetPasswordUser
function* resetPasswordUser({ payload }) {
  const { isTrainee } = payload;
  try {
    // if (isTrainee) {
    const signInUser = yield call(userTraineeResetPasswordRequest, payload);
    if (signInUser.message) {
      yield put(showAuthMessage({ error: signInUser.data.userMessage }));
    } else {
      yield put(showAuthMessage({ success: signInUser.data.userMessage }));
      var event = new CustomEvent("resetPassword", {
        detail: { isSuccess: true }
      });
      document.dispatchEvent(event);
    }
    // } else {
    //   const signInUser = yield call(userResetPasswordRequest, payload);
    //   if (signInUser.message) {
    //     yield put(showAuthMessage({ error: signInUser.data.data.userMessage }));
    //   } else {
    //     // yield put(userResetPasswordSuccess(signUpUser.data.userMessage));
    //     let user = {
    //       ...signInUser.data.data.user,
    //       organisation: signInUser.data.data.organisation,
    //       user_id: signInUser.data.data.user.id
    //         ? signInUser.data.data.user.id
    //         : signInUser.data.data.user._id,
    //       name: signInUser.data.data.user.userName,
    //       email: signInUser.data.data.user.email,
    //       contactNumber: signInUser.data.data.user.contactNumber,
    //       department: signInUser.data.data.user.department,
    //       designation: signInUser.data.data.user.designation,
    //       accessToken: signInUser.data.data.authToken,
    //       trainee: signInUser.data.data.trainee
    //     };
    //     localStorage.setItem("accessToken", user.accessToken);
    //     localStorage.setItem("user", JSON.stringify(user));
    //     yield put(userSignInSuccess(user));
    //   }
    // }
  } catch (error) {
    // yield put(showAuthMessage({ error }));
  }
}
function* forgotPasswordUser({ payload }) {
  const { email } = payload;
  const isTrainee = localStorage.getItem("isTrainee");
  try {
    if (isTrainee) {
      const requestInfo = yield call(userTraineeForgotPasswordRequest, email);
      if (requestInfo.data.error) {
        yield put(showAuthMessage({ error: requestInfo.data.userMessage }));
      } else {
        yield put(showAuthMessage({ success: requestInfo.data.userMessage }));
      }
    } else {
      const requestInfo = yield call(userForgotPasswordRequest, email);
      if (requestInfo.data.error) {
        yield put(showAuthMessage({ error: requestInfo.data.userMessage }));
      } else {
        yield put(showAuthMessage({ success: requestInfo.data.userMessage }));
      }
    }
  } catch (error) {
    // yield put(showAuthMessage({ error }));
  }
}

function* createUserWithEmailPassword({ payload }) {
  const { email, password } = payload;
  try {
    const signUpUser = yield call(
      createUserWithEmailPasswordRequest,
      email,
      password
    );
    if (signUpUser.message) {
      yield put(showAuthMessage({ error: signUpUser.message }));
    } else {
      localStorage.setItem("user_id", signUpUser.user.uid);
      yield put(userSignUpSuccess(signUpUser.user.uid));
    }
  } catch (error) {
    // yield put(showAuthMessage({ error }));
  }
}

function* findUserWithEmail({ payload }) {
  const { email } = payload;
  try {
    const findUser = yield call(findUserWithEmailRequest, email);
    const { data } = findUser;
    console.log({ findUser });
    if (!data.error && data.status === 200) {
      var userAuth = {};
      userAuth = {};
      localStorage.setItem("isTrainee", false);
      userAuth["isTrainee"] = false;
      userAuth["authMethod"] = data.data;
      userAuth["email"] = email;
      if(data.tenantId)
        userAuth["tenantId"] = data.tenantId;
      yield put(findUserSuccess(userAuth));
      // } else if( data.status === 400 ) {
      //   var userAuth = {}
      //   // userAuth =  data.data;
      //     localStorage.setItem('isTrainee', true);
      //   userAuth['isTrainee'] =  true;
      //   userAuth['authMethod'] =  "default";
      //   userAuth['email'] =  email;
      //   yield put(findUserSuccess(userAuth));
    } else {
      yield put(showAuthMessage({ error: data.userMessage }));
    }
  } catch (error) {
    // yield put(showAuthMessage({ error }));
  }
}
function* signInUserWithGoogle({ payload }) {
  console.log(payload,"google payload")
  try {
    const signUpUser = yield call(signInUserWithGoogleRequest);
    if (signUpUser.message) {
      yield put(showAuthMessage({ error: signUpUser.message }));
    } else {
      let user = {
        // userId: signUpUser.user.uid,
        // name: signUpUser.additionalUserInfo.name,
        // email: signUpUser.additionalUserInfo.email,
        // accessToken: signUpUser.credential.accessToken,
        // domain: signUpUser.additionalUserInfo.hd,
        authType: "google",
        authToken: signUpUser.credential.idToken
      };

      const signInUser = yield call(signInUserWithAuthTokenRequest, user);
      if (signInUser.data.error) {
        yield put(showAuthMessage({ error: signInUser.data.userMessage }));
      } else {
        if (payload.email == signInUser.data.data.user.email) {
          let user = {
            ...signInUser.data.data.user,
            organisation: signInUser.data.data.organisation,
            user_id: signInUser.data.data.user.id
              ? signInUser.data.data.user.id
              : signInUser.data.data.user._id,
            name: signInUser.data.data.user.userName,
            email: signInUser.data.data.user.email,
            contactNumber: signInUser.data.data.user.contactNumber,
            department: signInUser.data.data.user.department,
            designation: signInUser.data.data.user.designation,
            accessToken: signInUser.data.data.authToken,
            trainee: signInUser.data.data.trainee
          };
          localStorage.setItem("accessToken", user.accessToken);
          localStorage.setItem("user", JSON.stringify(user));
          yield put(userSignInSuccess(user));
        }else{
          yield put(clearUserData({}))
          yield put(showAuthMessage({ error: `Please use ${payload.email} to login.` }));
        }
        
      }
      // localStorage.setItem('user', JSON.stringify(user));
      // localStorage.setItem('accessToken', user.accessToken);
      // yield put(userGoogleSignInSuccess(user));
    }
  } catch (error) {
    // yield put(showAuthMessage({ error }));
  }
}

function* signInUserWithMicrosoft({ payload }) {
  try {
    const signUpUser = yield call(signInUserWithMicrosoftRequest, payload);
    if (signUpUser.accessToken) {
      let user = {
        // 'user_id': signUpUser.decodedIdToken.oid,
        // 'name': signUpUser.decodedIdToken.name,
        // 'email': signUpUser.decodedIdToken.preferred_username,
        authType: "microsoft",
        authToken: signUpUser.accessToken
      };
      const signInUser = yield call(signInUserWithAuthTokenRequest, user);
      if (signInUser.data.error) {
        yield put(showAuthMessage({ error: signInUser.data.userMessage }));
      } else {
        let user = {
          ...signInUser.data.data.user,
          organisation: signInUser.data.data.organisation,
          user_id: signInUser.data.data.user.id
            ? signInUser.data.data.user.id
            : signInUser.data.data.user._id,
          name: signInUser.data.data.user.userName,
          email: signInUser.data.data.user.email,
          contactNumber: signInUser.data.data.user.contactNumber,
          department: signInUser.data.data.user.department,
          designation: signInUser.data.data.user.designation,
          accessToken: signInUser.data.data.authToken,
          trainee: signInUser.data.data.trainee
        };
        localStorage.setItem("accessToken", user.accessToken);
        localStorage.setItem("user", JSON.stringify(user));
        yield put(userSignInSuccess(user));
      }
      // console.log({signUpUser.accessToken.})
      // localStorage.setItem('accessToken', user.accessToken);
      // localStorage.setItem('user', JSON.stringify(user));
      // yield put(userMicrosoftSignInSuccess(user));
    } else {
      yield put(
        showAuthMessage({ error: "Unable to login with Microsoft account." })
      );
    }
  } catch (error) {
    // yield put(showAuthMessage({ error }));
  }
}

function* signInUserWithFacebook() {
  try {
    const signUpUser = yield call(signInUserWithFacebookRequest);
    if (signUpUser.message) {
      yield put(showAuthMessage({ error: signUpUser.message }));
    } else {
      localStorage.setItem("user_id", signUpUser.user.uid);
      yield put(userFacebookSignInSuccess(signUpUser.user.uid));
    }
  } catch (error) {
    // yield put(showAuthMessage({ error }));
  }
}

function* signInUserWithGithub() {
  try {
    const signUpUser = yield call(signInUserWithGithubRequest);
    if (signUpUser.message) {
      yield put(showAuthMessage({ error: signUpUser.message }));
    } else {
      localStorage.setItem("user_id", signUpUser.user.uid);
      yield put(userGithubSignInSuccess(signUpUser.user.uid));
    }
  } catch (error) {
    // yield put(showAuthMessage({ error }));
  }
}

function* signInUserWithTwitter() {
  try {
    const signUpUser = yield call(signInUserWithTwitterRequest);
    if (signUpUser.message) {
      if (signUpUser.message.length > 100) {
        yield put(
          showAuthMessage({ error: "Your request has been canceled." })
        );
      } else {
        yield put(showAuthMessage({ error: signUpUser.message }));
      }
    } else {
      localStorage.setItem("user_id", signUpUser.user.uid);
      yield put(userTwitterSignInSuccess(signUpUser.user.uid));
    }
  } catch (error) {
    // yield put(showAuthMessage({ error }));
  }
}

function* signInUserWithEmailPassword({ payload }) {
  const { email, password, loginAs } = payload;
  try {
    const signInUser = yield call(signInUserWithEmailPasswordRequest, payload);
    console.log({ signInUser });
    if (signInUser.data.error) {
      yield put(showAuthMessage({ error: signInUser.data.userMessage }));
    } else if (signInUser.data.data.isTrainee && signInUser.data.data.isAdmin) {
      console.log("both user");
      var event = new CustomEvent("bothUser", {
        detail: { data: signInUser.data.data, isBothUser: true }
      });

      // Dispatch/Trigger/Fire the event
      document.dispatchEvent(event);
    } else if((!signInUser.data.data.user.passwordSet && signInUser.data.data.trainee && signInUser.data.data.user.organisationId.authMethod === "default") || (!signInUser.data.data.user.passwordReset && !signInUser.data.data.trainee && signInUser.data.data.user.organisation.authMethod === "default")){
      let user = {
        ...signInUser.data.data.user,
        organisation: signInUser.data.data.organisation,
        user_id: signInUser.data.data.user.id
          ? signInUser.data.data.user.id
          : signInUser.data.data.user._id,
        name: signInUser.data.data.user.userName,
        email: signInUser.data.data.user.email,
        contactNumber: signInUser.data.data.user.contactNumber,
        department: signInUser.data.data.user.department,
        designation: signInUser.data.data.user.designation,
        accessToken: signInUser.data.data.authToken,
        trainee: signInUser.data.data.trainee
      };
      // localStorage.setItem("accessToken", user.accessToken);
      // localStorage.setItem("user", JSON.stringify(user));

      var event = new CustomEvent("setPassword", {
        detail: { user: user  }
      });

      // Dispatch/Trigger/Fire the event
      document.dispatchEvent(event);
    } else {
      let user = {
        ...signInUser.data.data.user,
        organisation: signInUser.data.data.organisation,
        user_id: signInUser.data.data.user.id
          ? signInUser.data.data.user.id
          : signInUser.data.data.user._id,
        name: signInUser.data.data.user.userName,
        email: signInUser.data.data.user.email,
        contactNumber: signInUser.data.data.user.contactNumber,
        department: signInUser.data.data.user.department,
        designation: signInUser.data.data.user.designation,
        accessToken: signInUser.data.data.authToken,
        trainee: signInUser.data.data.trainee
      };
      localStorage.setItem("accessToken", user.accessToken);
      localStorage.setItem("user", JSON.stringify(user));
      console.log("login");
      // var event = new CustomEvent("login", {
      //   detail: { data: user, trainee: user.trainee }
      // });
      // document.dispatchEvent(event);
      yield put(userSignInSuccess(user));
    }
  } catch (error) {
    // yield put(showAuthMessage({ error }));
  }
}

function* onPingUser() {
  yield put(hideMessage());
  try {
    const userInfo = yield call(onPingRequest);
    if (userInfo.data.error) {
      console.log({ userInfo }, "userInfo");
      localStorage.removeItem("user");
      localStorage.removeItem("accessToken");
      // yield put(showAuthMessage({ error: signInUser.data.userMessage }));
      yield put(userSignOut());
    } else {
      console.log({ userInfo }, "userInfo");
      let user = {
        ...userInfo.data.data,
        name: userInfo.data.data.userName,
        trainee: false
      };
      localStorage.setItem("user", JSON.stringify(user));
    }
  } catch (error) {
    console.log("error", error);
    localStorage.removeItem("user");
    localStorage.removeItem("accessToken");
    localStorage.clear();
    // yield put(showAuthMessage({ error }));
    yield put(userSignOut());
  }
}

function* onPingElearningUser() {
  yield put(hideMessage());
  try {
    const userInfo = yield call(onElearningPingRequest);
    if (userInfo.data.error) {
      localStorage.removeItem("user");
      localStorage.removeItem("accessToken");
      // yield put(showAuthMessage({ error: signInUser.data.userMessage }));
      yield put(userSignOutSuccess());
    } else {
      console.log("userName", userInfo.data.data.userName);
      let user = {
        ...userInfo.data.data,
        name: userInfo.data.data.userName,
        trainee: true
      };
      localStorage.setItem("user", JSON.stringify(user));
      store.set("notificationCount", userInfo.data.data.notificationCount);
    }
  } catch (error) {
    // console.log("error", error)
    localStorage.removeItem("user");
    localStorage.removeItem("accessToken");

    localStorage.clear();
    store.removeItem("assignedTraining");
    // yield put(showAuthMessage({ error }));
    yield put(userSignOut());
  }
}
function* redirectUser({ payload }) {
  const fingerprint = btoa(navigator.userAgent);
  cookie.set("fngprt", fingerprint, {
    secure: process.env.NODE_ENV !== "dev"
  });
  const { token } = payload;
  store.set("accessToken",token)
  try {
    const userInfo = yield call(onUserRedirectRequest, token);
    // console.log({userInfo},"userInfo")
    if (userInfo.data.error) {
      userAuthToken=""
      localStorage.removeItem("user");
      localStorage.removeItem("accessToken");
      // yield put(showAuthMessage({ error: userInfo.data.userMessage }));

      var event = new CustomEvent("message", {
        detail: { error: userInfo.data.userMessage }
      });

      // Dispatch/Trigger/Fire the event
      document.dispatchEvent(event);
      // yield put(hideAuthLoader());
      yield put(showAuthMessage({ error: userInfo.data.userMessage }));
    } else {
      console.log(userInfo.data.data.user, "response ");
      let user = {
        ...userInfo.data.data.user,
        organisation: userInfo.data.data.organisation,
        user_id: userInfo.data.data.user.id
          ? userInfo.data.data.user.id
          : userInfo.data.data.user._id,
        name: userInfo.data.data.user.userName,
        email: userInfo.data.data.user.email,
        contactNumber: userInfo.data.data.user.contactNumber,
        department: userInfo.data.data.user.department,
        designation: userInfo.data.data.user.designation,
        accessToken: userInfo.data.data.authToken
        // trainee: false
      };
      store.set("accessToken",user.accessToken)
      store.set("user",user)
      localStorage.setItem("accessToken", user.accessToken);
      localStorage.setItem("user", JSON.stringify(user));
      // userAuthToken = user.accessToken
      // var event = new CustomEvent("redirectUser", {
      //   detail: { user:  user}
      // });

      // // Dispatch/Trigger/Fire the event
      // document.dispatchEvent(event);
      yield put(userSignInSuccess(user));
    }
  } catch (error) {
    yield put(
      showAuthMessage({
        error
      })
    );
    yield put(userSignOut());
  }
}
function* signOut() {
  console.log("SIGNOUT_USER");
  try {
    const signOutUser = yield call(signOutRequest);
    if (signOutUser === undefined) {
      localStorage.removeItem("user");
      localStorage.removeItem("accessToken");
      localStorage.clear();
      yield put(userSignOutSuccess(signOutUser));
    } else {
      yield put(showAuthMessage({ error: signOutUser.message }));
    }
  } catch (error) {
    // yield put(showAuthMessage({ error }));
  }
}
function* signOutAuth() {
  console.log("SIGNOUT_USER");
  try {
    const signOutUser = yield call(signOutRequest);
    if (signOutUser === undefined) {
      localStorage.removeItem("user");
      localStorage.removeItem("accessToken");
      localStorage.clear();
      yield put(userSignOutAuthSuccess(signOutUser));
    } else {
      // yield put(showAuthMessage({ error: signOutUser.message }));
    }
  } catch (error) {
    // yield put(showAuthMessage({ error }));
  }
}
export function* createUserAccount() {
  yield takeEvery(SIGNUP_USER, createUserWithEmailPassword);
}

export function* forgotPassword() {
  yield takeEvery(FORGOT_PASSWORD_USER, forgotPasswordUser);
}

export function* resetPassword() {
  yield takeEvery(RESET_PASSWORD_USER, resetPasswordUser);
}

export function* fetchUserWithEmail() {
  yield takeEvery(FIND_USER, findUserWithEmail);
}

export function* signInWithGoogle() {
  yield takeEvery(SIGNIN_GOOGLE_USER, signInUserWithGoogle);
}

export function* signInWithMicrosoft() {
  yield takeEvery(SIGNIN_MICROSOFT_USER, signInUserWithMicrosoft);
}

export function* signInWithFacebook() {
  yield takeEvery(SIGNIN_FACEBOOK_USER, signInUserWithFacebook);
}

export function* signInWithTwitter() {
  yield takeEvery(SIGNIN_TWITTER_USER, signInUserWithTwitter);
}

export function* signInWithGithub() {
  yield takeEvery(SIGNIN_GITHUB_USER, signInUserWithGithub);
}

export function* signInUser() {
  yield takeEvery(SIGNIN_USER, signInUserWithEmailPassword);
}

export function* onPing() {
  yield takeEvery(ON_PING, onPingUser);
}
export function* onTraineePing() {
  yield takeEvery(ON_PING_TRAINEE, onPingElearningUser);
}
export function* signOutUser() {
  yield takeEvery(SIGNOUT_USER, signOut);
}
export function* signOutAuthUser() {
  yield takeEvery(SIGNOUT_AUTH_USER, signOutAuth);
}
export function* onUserRedirect() {
  yield takeEvery(USER_REDIRECT, redirectUser);
}

export default function* rootSaga() {
  yield all([
    fork(signInUser),
    fork(forgotPassword),
    fork(resetPassword),
    fork(createUserAccount),
    fork(fetchUserWithEmail),
    fork(signInWithGoogle),
    fork(signInWithMicrosoft),
    fork(signInWithFacebook),
    fork(signInWithTwitter),
    fork(signInWithGithub),
    fork(onPing),
    fork(onTraineePing),
    fork(signOutUser),
    fork(signOutAuthUser),
    fork(onUserRedirect)
  ]);
}
